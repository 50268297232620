import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text } from "wini-web-components"
import { TableController } from "./controller"
import { Select1Form, TextFieldForm } from "../../../project-component/component-form"
import { randomGID } from "../../../Utils"

const PopupAddEditRelative = forwardRef(function PopupAddEditRelative(data, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID(), Form: { Required: true } } })
    const _tbController = new TableController(data.pid, "table")
    const [tables, setTables] = useState([])
    const dialogRef = useRef()

    const getTables = async () => {
        const res = await _tbController.getListSimple({ page: 1, size: 150, query: data.rels?.length ? `-@Name:{${data.rels.join(' | ')}}` : "*" })
        setTables(res.data ?? [])
    }

    const onSubmit = async (ev) => {
        const newRel = { ...ev, Column: ev.TablePK === ev.TableFK ? "ParentId" : `${ev.TablePK}Id` }
        closePopup(ref)
        data.onSuccess(newRel)
    }

    useEffect(() => {
        if (data.relItem) {
            Object.keys(data.relItem).forEach(prop => {
                methods.setValue(prop, data.relItem[prop])
            })
        }
        getTables()
    }, [])

    return <form className="col" style={{ flex: 1, width: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ flex: 1, width: '100%', padding: '2.4rem', gap: '2rem', alignItems: 'stretch' }}>
            <TextFieldForm
                required
                disabled
                register={methods.register}
                style={{ flex: 1 }}
                label={'TableFK'}
                errors={methods.formState.errors}
                name={'TableFK'}
                placeholder="TableFK"
            />
            <Select1Form
                required
                style={{ flex: 1 }}
                label={'TablePK'}
                control={methods.control}
                errors={methods.formState.errors}
                name={'TablePK'}
                options={tables.map(e => {
                    return {
                        id: e.Name,
                        name: e.Name,
                    }
                })}
                onChange={(v) => { if (v.id === data.relItem?.TableFK) methods.setValue('Form.Required', false) }}
            />
            <div className='col' style={{ gap: '1.6rem', alignItems: 'center' }}>
                <Text className='heading-9'>Required</Text>
                <Checkbox value={methods.watch('Form.Required')} disabled={methods.watch('TablePK') === methods.watch('TableFK')} onChange={(v) => { methods.setValue('Form.Required', v) }} />
            </div>
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3"
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Cancel ' + (data.item ? 'edit' : 'add'),
                        submitTitle: "Submit",
                        cancelTitle: "Cancel",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            >Cancel</Text>
            <div style={{ flex: 1 }}></div>
            <Button
                label={data.item ? 'Save' : 'Add'}
                className="button-primary"
                disabled={!methods.watch('TablePK') || !methods.watch('TableFK')}
                onClick={methods.handleSubmit(onSubmit)}
            />
        </div>
    </form >
})

export default PopupAddEditRelative