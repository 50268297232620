import { useEffect, useState } from 'react'
import { TextAreaForm, TextFieldForm } from '../../../../project-component/component-form'
import indexStyles from './index.module.css'
import { Text } from 'wini-web-components'
export default function CardInforContainer({ methods, methodsProps }) {
    const [tab, setTab] = useState(0)

    const renderTab = () => {
        switch (tab) {
            case 0:
                return <CardInfor methods={methods} />
            case 1:
                return <CardStyle methods={methodsProps} />
            default:
                return <div />
        }
    }

    return <div className={`col col6 ${indexStyles['infor-container']}`}>
        <div className={`row ${indexStyles['setting-card-tabbar']}`}>
            <Text onClick={() => { setTab(0) }} className={`heading-8 ${tab === 0 ? indexStyles['selected'] : ""}`}>Infor</Text>
            <Text onClick={() => { setTab(1) }} className={`heading-8 ${tab === 1 ? indexStyles['selected'] : ""}`}>Style</Text>
            <Text onClick={() => { setTab(2) }} className={`heading-8 ${tab === 2 ? indexStyles['selected'] : ""}`}>Action</Text>
        </div>
        {renderTab()}
    </div>
}

//#region CardInfor 
const CardInfor = ({ methods }) => {
    return <div className={`col`} style={{ gap: "1.6rem" }}>
        <TextFieldForm
            required
            name='Name'
            register={methods.register}
            errors={methods.formState.errors}
            label='Card name'
        />
        <TextAreaForm
            name='Description'
            register={methods.register}
            errors={methods.formState.errors}
            label='Form description'
        />
    </div>
}

//#region CardStyle
const CardStyle = ({ methods }) => {
    const [selected, setSelected] = useState()

    useEffect(() => {
        const _id = methods.getValues("selectedId")
        if (_id) setSelected(methods.getValues("layers").find(e => e.Id === _id))
        else setSelected(undefined)
    }, [methods.watch("layers"), methods.watch("selectedId")])

    return selected ? <div className='col' style={{ gap: "1.6rem" }}>
        <TextFieldForm
            name='namefield'
            register={methods.register}
            errors={methods.formState.errors}
            label='Name field'
        />
        <TextFieldForm
            name='NameField'
            register={methods.register}
            errors={methods.formState.errors}
            label='Name field'
        />
    </div> : null
}

const StyleContainer = () => {
    
}