import styles from '../view.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage, Winicon } from "wini-web-components"
import { TableController } from "../controller"
import SettingKeysTab from './settingKeys'
import { randomGID } from '../../../../Utils'
import { ComponentType, DataType, FEDataType } from '../da'
import { DataController, SettingDataController } from '../../../module/controller'
import SettingKeysForm from './settingKeysForm'
import { FormType } from '../../../module/da'
import { useForm } from 'react-hook-form'

const PopupAddEditTable = forwardRef(function PopupAddEditTable(data, ref) {
    const dialogRef = useRef()
    const [tab, setTab] = useState(0)
    const _tbController = new TableController(data.pid, "table")
    const _colController = new TableController(data.pid, "column")
    const _relController = new TableController(data.pid, "rel")
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID(), column: [{ Id: randomGID(), Name: 'Id', DataType: FEDataType.GID }, { Id: randomGID(), Name: 'DateCreated', DataType: FEDataType.DATETIME }, { Id: randomGID(), Name: 'Name', DataType: FEDataType.STRING }, { Id: randomGID(), Name: 'Sort', DataType: FEDataType.NUMBER }], rel: [] } })
    const [initCols, setInitCols] = useState([])
    const [initRels, setInitRels] = useState([])

    const onSubmit = (item) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: `Confirm ${data.item ? 'edit' : 'add'} table`,
            submitTitle: "Submit",
            cancelTitle: "Cancel",
            onSubmit: async () => {
                let tbItem = { ...item };
                let cols = tbItem.column
                let rels = tbItem.rel
                tbItem.DateCreated ??= Date.now()
                delete tbItem.column
                delete tbItem.rel
                const _newCols = cols.filter(e => e.Name?.length).sort((a, b) => (a.Name === "Name" || a.Name === "Id") ? -1 : (b.Name === "Name" || b.Name === "Id") ? 1 : 0)
                let _props = {}
                const res = await _tbController.add([tbItem])
                if (res.code !== 200) return ToastMessage.errors(res.message)
                if (data.item) {
                    if (initCols.length) {
                        const _deleteIds = initCols.filter(id => {
                            const _col = _newCols.find(el => id === el.Id)
                            const _isDelete = !_col || _col.Query?.length // so sánh list column ban đầu vs list column hiện tại, nếu phần tử có tồn tại ở list ban đầu mà ko tồn tại ở list hiện tại hoặc phần tử có tồn tại query(caculate) thì call api xóa phần tử đó đi
                            return _isDelete
                        })
                        if (_deleteIds.length) {
                            const deleteRes = await _colController.delete(_deleteIds)
                            if (deleteRes.code !== 200) ToastMessage.errors(deleteRes.message)
                        }
                    }
                }
                // 
                const colRes = await _colController.add(_newCols.map((e, i) => {
                    delete e.id
                    e.DateCreated ??= Date.now()
                    e.Setting ??= { Title: e.Name, IsHidden: false, Sort: i }
                    e.Form ??= { Label: e.Name, Required: true, Sort: i, }
                    switch (e.DataType) {
                        case FEDataType.GID:
                            _props[e.Name] = DataType.TAG
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.textField
                            break;
                        case FEDataType.STRING:
                            _props[e.Name] = DataType.TEXT
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.textField
                            if (e.Form.Options?.length) {
                                debugger
                                e.Form.Options = e.Form.Options.map(e => { return { id: `${e.id}`, name: e.name, disabled: e.disabled } })
                            }
                            break;
                        case FEDataType.BOOLEAN:
                            _props[e.Name] = DataType.TAG
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.checkbox
                            break;
                        case FEDataType.NUMBER:
                            _props[e.Name] = DataType.NUMERIC
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.textField
                            if (e.Form.Options?.length) {
                                e.Form.Options = e.Form.Options.map(e => { return { id: typeof e.id === "string" ? parseFloat(e.id) : e.id, name: e.name, disabled: e.disabled } })
                            }
                            break;
                        case FEDataType.DATE:
                            _props[e.Name] = DataType.NUMERIC
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.datePicker
                            break;
                        case FEDataType.DATETIME:
                            _props[e.Name] = DataType.NUMERIC
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.dateTimePicker
                            break;
                        case FEDataType.MONEY:
                            _props[e.Name] = DataType.NUMERIC
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.textField
                            if (e.Form.Options?.length) {
                                e.Form.Options = e.Form.Options.map(e => { return { id: typeof e.id === "string" ? parseInt(e.id.replaceAll(",", "")) : e.id, name: e.name, disabled: e.disabled } })
                            }
                            break;
                        case FEDataType.HTML:
                            _props[e.Name] = DataType.TEXT
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.ckEditor
                            break;
                        case FEDataType.PASSWORD:
                            _props[e.Name] = DataType.TAG
                            if (typeof e.Form !== "string") e.Form.ComponentType = ComponentType.textField
                            break;
                        case FEDataType.FILE:
                            _props[e.Name] = DataType.TEXT
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.upload
                            break;
                        default:
                            if (typeof e.Form !== "string") e.Form.ComponentType ??= ComponentType.textField
                            if (e.Name === "DateCreated") {
                                e.DataType = FEDataType.DATETIME
                                _props[e.Name] = DataType.NUMERIC
                            }
                            break;
                    }
                    if (typeof e.Setting !== "string") e.Setting = JSON.stringify(e.Setting)
                    if (typeof e.Form !== "string") e.Form = JSON.stringify(e.Form)
                    if (e.Form.Validate) {
                        e.Form.Validate = e.Form.Validate.map(e => {
                            let val = { ...e }
                            delete val.id
                            return val
                        })
                    }
                    return { ...e, TableName: tbItem.Name }
                }))
                if (colRes.code !== 200) return ToastMessage.errors(colRes.message)
                if (initRels.length) {
                    const _deleteIds = initRels.filter(id => !rels.some(el => id === el.Id))  // so sánh list relative ban đầu vs list relative hiện tại, nếu phần tử có tồn tại ở list ban đầu mà ko tồn tại ở list hiện tại thì call api xóa phần tử đó đi
                    if (_deleteIds.length) {
                        const deleteRes = await _relController.delete(_deleteIds)
                        if (deleteRes.code !== 200) ToastMessage.errors(deleteRes.message)
                    }
                }
                const rellRes = await _relController.edit(rels.map((e, i) => {
                    delete e.id
                    _props[e.Column] = DataType.TAG
                    e.DateCreated ??= Date.now()
                    e.Setting ??= { Title: e.TablePK, IsHidden: true, Sort: i + _newCols.length }
                    e.Form ??= { Label: e.Column, Sort: i, Required: true }
                    if (typeof e.Setting !== "string") e.Setting = JSON.stringify(e.Setting)
                    if (typeof e.Form !== "string") e.Form = JSON.stringify(e.Form)
                    return { ...e, TableFK: tbItem.Name }
                }))
                if (rellRes.code !== 200) return ToastMessage.errors(rellRes.message)
                // rebuildIndex search
                const _dataController = new DataController({ pid: data.pid, module: tbItem.Name })
                const idxRes = await _dataController.buildIndex(_props)
                if (idxRes.code !== 200) return ToastMessage.errors(idxRes.message)
                // 
                if (!data.item) {
                    const _settingFormController = new SettingDataController({ pid: data.pid, setting: "form" })
                    let _formProps = {}
                    const propList = [..._newCols, ...rels].filter(e => e.Name !== "Id" && !e.Query)
                    propList.forEach((p, i) => {
                        _formProps[p.Name ?? p.Column] = i
                    })
                    const newFormRes = await _settingFormController.action("add", {
                        data: [{
                            Id: randomGID(),
                            Name: "Default form",
                            TbName: tbItem.Name,
                            DateCreated: Date.now(),
                            Description: "default description...",
                            Type: FormType.formFullLeftLabelCol,
                            Props: JSON.stringify(_formProps)
                        }]
                    })
                    if (newFormRes.code !== 200) return ToastMessage.errors(newFormRes.message)
                }
                ToastMessage.success(`${data.item ? "Update" : "Add"} ${tbItem.Name} table successfully!`)
                closePopup(ref)
                data.onSuccess()
            }
        })
    }

    const getData = async () => {
        Object.keys(data.item).forEach(prop => {
            methods.setValue(prop, data.item[prop])
        })
        const _relRes = await _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${data.item.Name}}` })
        if (_relRes.count) {
            methods.setValue("rel", _relRes.data)
            setInitRels(_relRes.data.map(e => e.Id))
        }
        const _colRes = await _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${data.item.Name}}` })
        if (_colRes.count) {
            _colRes.data = _colRes.data.sort((a, b) => a.Name === "Id" ? -1 : b.Name === "Id" ? 1 : a.DateCreated - b.DateCreated)
            methods.setValue("column", _colRes.data)
            setInitCols(_colRes.data.map(e => e.Id))
        }
    }

    const renderTabView = () => {
        if (data.item && !initCols.length) return <div />
        switch (tab) {
            case 0:
                return <SettingKeysTab pid={data.pid} initCols={initCols} methods={methods} />
            case 1:
                return <SettingKeysForm methods={methods} />
            default:
                return <div />
        }
    }

    useEffect(() => {
        if (data.item) getData()
    }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ gap: '0.8rem', padding: '0.8rem', paddingLeft: '2.4rem', borderColor: "#d7d7db" }}>
            <Text className='heading-8' style={{ flex: 1 }}>{data.item?.Name ?? "New"} table</Text>
            <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><Winicon src={"fill/user interface/e-remove"} size={"2.4rem"} /></button>
        </div>
        <div className={`row ${styles['tab-setting-container']}`}>
            <button type='button' className={`row label-4 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Key</button>
            <button type='button' className={`row label-4 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Form</button>
        </div>
        {renderTabView()}
        <div className="row popup-footer" style={{ borderTop: "var(--neutral-bolder-border-color)" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Hủy ' + (data.item ? 'chỉnh sửa' : 'thêm mới'),
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className="button-primary"
                disabled={!methods.watch("Name")?.trim()?.length}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(onSubmit)}
            />
        </div>
    </div>
})

export default PopupAddEditTable



