import styles from './view.module.css'
import { Button, Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DataController } from '../controller'
import PopupAddEditData from './settingForm'
import PopupFormFilter from './formSearch'
import { TableController } from "../../home/table/controller";
import { getTableConfig } from '../config'
import { OutlineAppSettings, OutlineAppStar, OutlineEnlargeVertical, OutlineMoveLayerLeft, OutlineMoveLayerRight, OutlineViewOff } from '../../../assets/icon'
import { useForm } from 'react-hook-form'
import PopupManageTableFK from './tableFKManger'
import { PopupActions, DrawerSettingTableManager } from './settingTableManager'
import { FEDataType } from '../../home/table/da'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PopupSearchIn from './seachIn'
import { BaseDA } from '../../../da/baseDA'
import ConfigApi from '../../../da/configApi'

const searchkeyVl = "keysearch";
export default function ModuleListView({ module }) {
    const selectedM = useSelector((store) => store.module.data)
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const _pid = searchParams.get("pid")
    const _colController = new TableController(_pid, "column")
    const _relController = new TableController(_pid, "rel")
    const _dataController = new DataController({ pid: _pid, module: module })
    const ref = useRef()
    const dialogRef = useRef()
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [data, setData] = useState({ data: [], total: undefined })
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const methods = useForm({ shouldFocusError: false })
    const [selected, setSelected] = useState([])

    const getData = async (page, size) => {
        let _query = []
        let sortby = undefined
        const filterByParentId = rels.some(e => e.TablePK === module)
        for (const [key, value] of searchParams) {
            if (key !== "pid") {
                const _col = cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                switch (_col?.DataType) {
                    case FEDataType.STRING:
                        _query.push(`@${key}:${value}`)
                        break;
                    case FEDataType.BOOLEAN:
                        _query.push(`@${key}:{${value}}`)
                        break;
                    default:
                        if (key == "sortby") {
                            sortby = { prop: value.split(",")[0], direction: value.includes("asc") ? "ASC" : "DESC" }
                        }
                        if (key == searchkeyVl) {
                            methods.setValue('search-key-value', value)
                        }
                        if (key == "searchin") {
                            const listKeySearch = value.split(',')
                            if (listKeySearch.length > 0) {
                                listKeySearch.forEach(element => {
                                    _query.push(`@${element}:${searchParams.get(searchkeyVl)}`)
                                });
                            }
                        }
                        if (_col && [FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(_col.DataType)) {
                            if (_query.every(e => !e.startsWith(`@${_col.Name}:`))) _query.push(`@${_col.Name}:[${searchParams.get(`${_col.Name}_min`)} ${searchParams.get(`${_col.Name}_max`)}]`)
                        }
                        break;
                }
            }
        }
        const res = await _dataController.aggregateList({
            page: page ?? pageDetails.page ?? 1,
            size: size ?? pageDetails.size ?? 10,
            filter: filterByParentId ? `APPLY exists(@ParentId) AS __exist FILTER (@__exist == 0)` : undefined,
            searchRaw: _query.length && searchParams.has(searchkeyVl) ? _query.join(" | ") : "*",
            sortby: sortby ? [sortby] : undefined
        })
        if (res.code === 200) {
            const colFiles = cols.filter(e => e.DataType === FEDataType.FILE).map(e => e.Name)
            if (colFiles.length) {
                const _fileId = methods.getValues("_files") ?? []
                const _tmpFileIds = res.data.map(e => colFiles.map(c => e[c])).flat(Infinity).filter(id => id?.length && _fileId.every(e => e.id !== id) && !id.startsWith("http"))
                BaseDA.getFilesInfor(_tmpFileIds).then(res => {
                    if (res?.length) methods.setValue("_files", [..._fileId, ...res.map(e => { return { ...e, type: e.type ? e.type : [".png", ".svg", ".jpg"].some(t => e.name.endsWith(t)) ? "image" : "file" } })])
                })
            }
            _dataController.group({ searchRaw: `@ParentId:{${res.data.map(e => `*${e.Id}*`).join(" | ")}}`, reducers: `GROUPBY 1 @ParentId REDUCE COUNT 0 AS _count` }).then(res => {
                if (res.code === 200) methods.setValue("__countChildren", res.data)
            })
            setData({ data: res.data, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const deleteItem = async (item) => {
        let _countChildren = methods.watch("__countChildren")?.find(e => e.ParentId === item.Id)?.["_count"]
        if (_countChildren) _countChildren = parseInt(_countChildren)
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: "Confirm delete",
            content: `Are you sure to delete this ${module}?${_countChildren ? ` This ${module} has ${_countChildren} children` : ""}`,
            submitTitle: "Delete",
            cancelTitle: "Cancel",
            onSubmit: async () => {
                const _deleteIds = [item.Id]
                if (_countChildren) {
                    const resChildren = await _dataController.getListSimple({ page: 1, size: 2000, query: `@ParentId:{${item.Id}}`, returns: ["Id"] })
                    if (resChildren.code === 200) _deleteIds.push(...resChildren.data.map(e => e.Id))
                }
                const res = await _dataController.delete(_deleteIds)
                if (res.code === 200) {
                    ToastMessage.success(`Delete ${module} successfully!`)
                    getData(pageDetails.page, pageDetails.size)
                } else ToastMessage.errors(res.message)
            }
        })

    }

    const searchKeyAction = async (e) => {
        if (e.target.value.length == 0) {
            searchParams.delete(searchkeyVl)
        } else {
            if (searchParams.has(searchkeyVl))
                searchParams.set(searchkeyVl, e.target.value.trim());
            else searchParams.append(searchkeyVl, e.target.value.trim());
        }
        navigate('?' + searchParams.toString());

        getData(pageDetails.page, pageDetails.size)
    }

    const showPopupSettingColumn = (ev, _col) => {
        const _tbCellRect = ev.target.closest(`div[class*="header"]`).getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: `calc(100vw - ${_tbCellRect.right}px)`, top: _tbCellRect.bottom },
            content: <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '20rem' }}>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerLeft />
                    <Text>Move to start</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerRight />
                    <Text>Move to end</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }} onClick={() => {
                    closePopup(ref)
                    sortTable({ col: _col })
                }}>
                    <OutlineEnlargeVertical />
                    <Text>Sort</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineViewOff />
                    <Text>Hide column</Text>
                </button>
            </div>
        })
    }

    const showSearchBox = (event) => {
        var listFilterSearch = cols.filter(e => !e.Setting.IsHidden).filter(m => m.DataType == FEDataType.STRING)
        for (const [key, value] of searchParams) {
            if (key === "searchin") {
                const valueList = value.split(',')
                if (valueList) {
                    listFilterSearch.forEach(element => {
                        if (valueList.find((e) => e == element.Setting.Title)) {
                            element.Setting.isChecked = true
                        }
                    });
                }
            }
        }
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', width: event.target.closest('div').getBoundingClientRect().width, top: event.target.closest('div').getBoundingClientRect().bottom, left: event.target.closest('div').getBoundingClientRect().x, bottom: 0, maxHeight: '100%' },
            content: <PopupSearchIn ref={ref}
                cols={listFilterSearch} />
        })
    }

    const showDrawer = () => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: 0, bottom: 0, height: `calc(100dvh - 18.8rem)`, borderRadius: 0, maxHeight: '100%' },
            content: <DrawerSettingTableManager
                ref={ref}
                pid={_pid}
                cols={cols}
                rels={rels}
                actions={actions}
                onChange={({ newCols, newRels, newActions }) => {
                    if (newCols) setCols(newCols)
                    if (newRels) setRels(newRels)
                    if (newActions) setActions(newActions)
                }}
            />
        })
    }

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            hideButtonClose: true,
            content: <PopupAddEditData
                ref={ref}
                id={item?.Id?.toLowerCase()?.replaceAll("-", "")}
                pid={_pid}
                module={module}
                onSuccess={() => { getData(pageDetails.page, pageDetails.size) }}
            />
        })
    }

    const showPopupFilter = () => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            content: <PopupFormFilter
                ref={ref}
                pid={_pid}
                module={module}
                cols={cols.filter(e => !e.Setting.IsHidden)}
                onSuccess={getData}
            />
        })
    }

    const showPopupAction = (ev, item) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', left: ev.pageX, top: ev.pageY },
            content: <PopupActions
                ref={ref}
                pid={_pid}
                item={item}
                onEdit={() => { showPopupAddEdit(item) }}
                onDelete={() => { deleteItem(item) }}
                actions={actions.filter(e => !e.Shortcut.IsHidden)}
                onSelectAction={(_action) => {
                    closePopup(ref)
                    showPopup({
                        ref: ref,
                        style: { height: '80%', width: '80%' },
                        hideButtonClose: true,
                        content: <PopupManageTableFK ref={ref} pid={_pid} item={item} rel={{ ..._action, TablePK: module }} />
                    })
                }}
            />
        })
    }

    const sortTable = ({ col }) => {
        if (col.Id) {
            var searchkey = "sortby";
            if (searchParams.has(searchkey))
                if (col.Name === searchParams?.get("sortby")?.split(",")[0]) {
                    searchParams.set(searchkey, `${col.Name},${searchParams?.get("sortby")?.split(",")[1] === 'asc' ? 'desc' : 'asc'}`);
                } else {
                    searchParams.set(searchkey, `${col.Name},${searchParams?.get("sortby")?.split(",")[1]}`);
                }
            else searchParams.append(searchkey, `${col.Name},asc`);

            navigate('?' + searchParams.toString());
            getData(pageDetails.page, pageDetails.size)

        }
    }

    const onMouseEnter = () => {
        document.body.onmousemove = (ev) => {
            if (`${ev.target.className}`.includes("resize-cell-stick")) return
            if (ev.target.classList.contains("header-cell")) {
                let cellElement = ev.target
                const _cellRect = cellElement.getBoundingClientRect()
                // if ((ev.pageX <= (_cellRect.x + 8) && ev.pageX >= _cellRect.x) || (ev.pageX <= _cellRect.right && ev.pageX >= (_cellRect.right - 8))) {
                if (ev.pageX <= _cellRect.right && ev.pageX >= (_cellRect.right - 8)) {
                    let _resizeSticky = document.body.querySelector(`div[class*="resize-cell-stick"]`)
                    if (!_resizeSticky) {
                        _resizeSticky = document.createElement("div")
                        _resizeSticky.className = styles["resize-cell-stick"]
                        document.body.appendChild(_resizeSticky)
                        _resizeSticky.onmousedown = () => {
                            cellElement.style.backgroundColor = "var(--neutral-bolder-background-color)"
                            document.body.onmousemove = (event) => {
                                event.stopImmediatePropagation()
                                event.preventDefault()
                                if (ev.pageX <= _cellRect.x + 4) {
                                    [cellElement, ...document.body.querySelectorAll(`div[class*="${cellElement.id}"]`)].forEach(e => {
                                        e.style.width = `${_cellRect.width + ev.pageX - event.pageX}px`
                                    })
                                } else {
                                    [cellElement, ...document.body.querySelectorAll(`div[class*="${cellElement.id}"]`)].forEach(e => {
                                        e.style.width = `${_cellRect.width + event.pageX - ev.pageX}px`
                                    })
                                }
                                _resizeSticky.style.left = `${cellElement.getBoundingClientRect().right - 4}px`
                            }
                            document.body.onmouseup = (event) => {
                                document.body.onmousemove = undefined
                                document.body.onmouseup = undefined
                                cellElement.style.backgroundColor = ''
                                document.body.querySelector(`div[class*="resize-cell-stick"]`)?.remove()
                            }
                        }
                    }
                    _resizeSticky.style.cssText = `top: ${_cellRect.y}px;left: ${(ev.pageX <= _cellRect.x + 8 ? _cellRect.x : _cellRect.right) - 4}px`
                    return
                }
            } else if (!ev.target.closest(".header-cell")) {
                document.body.onmousemove = undefined
                document.body.onmouseup = undefined
                document.body.querySelector(`div[class*="resize-cell-stick"]`)?.remove()
            }
        }
    }

    useEffect(() => {
        const _rels = rels.filter(e => !e.Setting.IsHidden)
        if (_rels.length && data.total) {
            _rels.forEach((_rel) => {
                const _relItems = methods.getValues(_rel.Column) ?? []
                let _relIds = []
                data.data.map(e => e[_rel.Column]?.split(",") ?? []).flat(Infinity).forEach(id => {
                    if (!_relIds.includes(id)) _relIds.push(id)
                })
                _relIds = _relIds.filter(id => _relItems.every(e => e.Id !== id))
                if (_relIds.length) {
                    const _relDataCotroller = new DataController({ pid: _pid, module: _rel.TablePK })
                    _relDataCotroller.getByListId(_relIds).then(res => {
                        if (res.code === 200) methods.setValue(_rel.Column, [..._relItems, ...res.data.filter(e => e != undefined)])
                    })
                }
            })
        }
    }, [rels.filter(e => !e.Setting.IsHidden).length, data])

    const initTable = async () => {
        const resPK = await _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${module}}`, returns: ["Id", "Column", "Setting", "TablePK"] })
        if (resPK.code === 200) {
            setRels(resPK.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                return e
            }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        }
        // 
        _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${module}}`, returns: ["Id", "Name", "Setting", "DataType", "Form"] }).then(res => {
            if (res.code === 200) {
                const _colRes = res.data.map((e, i) => {
                    e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                    e.Form = e.Form ? JSON.parse(e.Form) : {}
                    return e
                }).sort((a, b) => a.Name === "Name" ? -1 : b.Name === "Name" ? 1 : a.Setting.Sort - b.Setting.Sort)
                setCols(_colRes.map((e, i) => { return { ...e, Setting: { ...e.Setting, Sort: i } } }))
            }
        })
        // 
        _relController.getListSimple({ page: 1, size: 100, query: `@TablePK:{${module}}`, returns: ["Id", "Column", "Shortcut", "TableFK"] }).then(res => {
            if (res.code === 200) setActions(res.data.map((e, i) => {
                e.Shortcut = e.Shortcut ? JSON.parse(e.Shortcut) : { Title: e.Name, Sort: i, IsHidden: true }
                return e
            }).sort((a, b) => a.Shortcut.Sort - b.Shortcut.Sort))
        })
    }

    useEffect(() => {
        if (cols.length) getData()
    }, [cols.length, module, location])

    useEffect(() => {
        methods.reset()
        methods.reset()
        initTable()
    }, [_pid, module])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-bolder-border-color)' }}>
            <div className='row' style={{ flex: 1, gap: '2.4rem' }}>
                <button type='button' className='row' style={{ gap: '1.6rem' }}>
                    <Winicon src='outline/files/folder' size={'3.2rem'} />
                    <Text className='heading-5'>{selectedM?.Name}</Text>
                    <Winicon src={"fill/arrows/down-arrow"} size={"1.6rem"} />
                </button>
                <Button label='Button' style={{ width: '6.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }} />
                <OutlineAppStar size={'2.4rem'} />
                <div className='row' style={{ gap: '0.6rem', borderRadius: '10rem', backgroundColor: "var(--neutral-main-background-color)", border: "var(--neutral-bolder-border-color)", height: '2.4rem', padding: '0 0.8rem' }}>
                    <div style={{ width: '0.6rem', height: '0.6rem', borderRadius: '50%', backgroundColor: "var(--neutral-text-subtitle-color)" }} />
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>On track</Text>
                    <Winicon src={"fill/arrows/down-arrow"} size={"0.9rem"} />
                </div>
            </div>
            <div className='row' style={{ flex: 1, justifyContent: "end" }}>
                <div className='row'>
                    {Array.from({ length: 5 }).map((_, i) => {
                        if (i === 4) return <div key={'mem-' + i} className='row icon-button24' style={{ backgroundColor: "#f4f4f5", borderRadius: "50%", transform: `translateX(-${i * 0.4}rem)` }}><Winicon src={"fill/text/menu-dots"} /></div>
                        else return <div key={'mem-' + i} className='row icon-button24' style={{ borderRadius: "50%", transform: i === 0 ? undefined : `translateX(-${i * 0.4}rem)` }}><img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ width: '100%', height: '100%' }} /></div>
                    })}
                </div>
                <Button
                    label='Share'
                    className='button-primary'
                    style={{ padding: '0 1.2rem', borderRadius: '0.4rem' }}
                    prefix={<Winicon src={"fill/arrows/share-5"} size={"1.4rem"} />}
                />
                <div className='row divider' style={{ height: '1.6rem' }} />
                <Button
                    label='Customize'
                    style={{ padding: '0 1.2rem', borderRadius: '0.4rem', backgroundColor: "transparent", border: "var(--neutral-bolder-border-color)", color: "var(--neutral-text-subtitle-color)" }}
                    prefix={<Winicon src='fill/user interface/grid-interface' size={"1.4rem"} />}
                />
            </div>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <div className='row' style={{ height: '3.2rem', alignItems: "stretch", border: "var(--neutral-bolder-border-color)", borderRadius: '0.8rem' }} >
                    <button type='button' className='row' style={{ padding: '0 1.2rem', borderRight: "var(--neutral-bolder-border-color)", gap: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                        <Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Add {selectedM?.Name}</Text>
                    </button>
                    <div className='row' style={{ padding: '0.8rem' }}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                    suffix={<button type='button' onClick={(event) => showSearchBox(event)}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </button>}
                    name='txtSearch'
                    onComplete={searchKeyAction}
                    defaultValue={`${searchParams.get(searchkeyVl) ?? ''}`}
                    register={methods.register('search-key-value')}
                    style={{ padding: '0 1.2rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { showPopupFilter() }}>
                    <Winicon src='outline/user interface/setup-preferences' size={'1.6rem'} />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='fill/arrows/enlarge' size={'1.6rem'} />
                    <Text className='button-text-3'>Sort</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={showDrawer}>
                    <OutlineAppSettings />
                    <Text className='button-text-3'>Setting</Text>
                </button>
            </div>
            {cols.length ? <div className={`col ${styles['table']}`}>
                <div className={`row ${styles['header']}`} onMouseOver={onMouseEnter}>
                    {cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                        const { _minW } = getTableConfig(_col)
                        if (i > 0) {
                            return <div key={_col.Id} id={_col.Id} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort }} className='row header-cell' >
                                <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                {_col.Name == searchParams?.get("sortby")?.split(",")[0] && <button type='button' className={`row icon-button20`} onClick={() => {
                                    sortTable({ col: _col })
                                }}>
                                    <Winicon src={`outline/arrows/circle-arrow-${searchParams?.get("sortby")?.split(",")[1] == "asc" ? "up" : "down"}`} />
                                </button>}
                                <div style={{ flex: 1 }} />
                                <button type='button' className={`row icon-button16 ${styles["sort"]}`} onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                    <Winicon src='fill/arrows/down-arrow' size={'0.8rem'} />
                                </button>
                            </div>
                        } else {
                            return <div ref={(ref) => {
                                if (ref && !_col.Setting?.Width) {
                                    const _rect = ref.getBoundingClientRect()
                                    setCols(cols.map(e => {
                                        if (e.Id === _col.Id) return { ..._col, Setting: { ..._col.Setting, Width: `${Math.round(_rect.width / 10)}rem` } }
                                        else return e
                                    }))
                                }
                            }} key={_col.Id} id={_col.Id} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }} className='row header-cell' >
                                <Checkbox size={'1.6rem'} value={selected.length ? (data.data.every(e => selected.includes(e.Id)) ? true : undefined) : false} onChange={(v) => {
                                    const _filter = selected.filter(id => data.data.every(e => id !== e.Id))
                                    if (v) setSelected([..._filter, ...data.data.map(e => e.Id)])
                                    else setSelected(_filter)
                                }} />
                                <div className='row' style={{ height: '100%', gap: '1.2rem', flex: 1 }}>
                                    <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                    {_col.Name == searchParams?.get("sortby")?.split(",")[0] && <button type='button' className={`row icon-button20`} onClick={() => { sortTable({ col: _col }) }}>
                                        <Winicon src={`outline/arrows/circle-arrow-${searchParams?.get("sortby")?.split(",")[1] == "asc" ? "up" : "down"}`} />
                                    </button>}
                                    <div style={{ flex: 1 }} />
                                    <button type='button' className={`row icon-button16 ${styles["sort"]}`} onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                        <Winicon src='fill/arrows/down-arrow' size={'0.8rem'} />
                                    </button>
                                </div>
                            </div>
                        }
                    })}
                    {rels.filter((e) => !e.Setting.IsHidden).map((_rel, i) => {
                        return <div key={`${_rel.Id}`} id={_rel.Id} className='row' style={{ width: _rel.Setting.Width ?? '20rem', order: _rel.Setting.Sort ?? (cols.length + i) }}>
                            <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{_rel.Setting?.Title ?? _rel.Column}</Text>
                        </div>
                    })}
                    <div key={'hlast'} className='row' style={{ order: 100 }}>
                        <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis", textAlign: "center" }} maxLine={1}>Action</Text>
                    </div>
                </div>
                {
                    data.data.map((item, index) => <TableRow
                        key={`${item.Id}-${index}`}
                        index={index}
                        item={item}
                        cols={cols}
                        rels={rels}
                        methods={methods}
                        selected={selected}
                        dataController={_dataController}
                        setSelected={setSelected}
                        showPopupAddEdit={showPopupAddEdit}
                        showPopupAction={showPopupAction}
                    />)
                }
            </div> : null}
            <div className='row' style={{ height: '5.6rem', borderTop: "var(--neutral-bolder-border-color)" }}>
                <Pagination
                    currentPage={pageDetails.page}
                    itemPerPage={pageDetails.size}
                    totalItem={data?.total ?? 0}
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                            setSelected([])
                        }
                    }}
                />
            </div>
        </div>
    </div>
}

export const TableRow = ({ item, index, dataController, cols = [], rels = [], methods, showPopupAddEdit, showPopupAction, onDelete, selected = [], setSelected, space = 0 }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const methodsChild = useForm({ shouldFocusError: false })
    const totalCount = useMemo(() => {
        const _count = methods.watch("__countChildren")?.find(e => e.ParentId === item.Id)?.["_count"]
        return _count?.length ? parseInt(_count) : undefined
    }, [methods.watch("__countChildren")])
    const checkValue = useMemo(() => {
        const _contains = selected.includes(item.Id)
        if (!totalCount) return _contains
        let _tmp = false
        const _childrenContains = data.length && data.every(e => {
            const _check = selected.includes(e.Id)
            if (_check) _tmp = true
            return _check
        })
        if (_childrenContains && data.length === totalCount && !_contains) {
            setSelected([...selected.filter(id => data.every(e => e.Id !== id)), item.Id])
        }
        return _contains ? true : _tmp ? undefined : false
    }, [selected.length, totalCount, data.length])

    const getChildren = async (page) => {
        let _query = []
        let sortby = undefined
        for (const [key, value] of searchParams) {
            if (key !== "pid") {
                const _col = cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                switch (_col?.DataType) {
                    case FEDataType.STRING:
                        _query.push(`@${key}:${value}`)
                        break;
                    case FEDataType.BOOLEAN:
                        _query.push(`@${key}:{${value}}`)
                        break;
                    default:
                        if (key == "sortby") {
                            sortby = { prop: value.split(",")[0], direction: value.includes("asc") ? "ASC" : "DESC" }
                        }
                        if (key == searchkeyVl) {
                            methods.setValue('search-key-value', value)
                        }
                        if (key == "searchin") {
                            const listKeySearch = value.split(',')
                            if (listKeySearch.length > 0) {
                                listKeySearch.forEach(element => {
                                    _query.push(`@${element}:${searchParams.get(searchkeyVl)}`)
                                });
                            }
                        }
                        if (_col && [FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(_col.DataType)) {
                            if (_query.every(e => !e.startsWith(`@${_col.Name}:`))) _query.push(`@${_col.Name}:[${searchParams.get(`${_col.Name}_min`)} ${searchParams.get(`${_col.Name}_max`)}]`)
                        }
                        break;
                }
            }
        }
        const res = await dataController.aggregateList({
            page: page ?? 1,
            size: 10,
            searchRaw: `@ParentId:{${item.Id}} ` + (_query.length && searchParams.has(searchkeyVl) ? _query.join(" | ") : ""),
            sortby: sortby ? [sortby] : undefined
        })
        if (res.code === 200) {
            const colFiles = cols.filter(e => e.DataType === FEDataType.FILE).map(e => e.Name)
            if (colFiles.length) {
                const _fileId = methodsChild.getValues("_files") ?? []
                const _tmpFileIds = res.data.map(e => colFiles.map(c => e[c])).flat(Infinity).filter(id => id?.length && _fileId.every(e => e.id !== id) && !id.startsWith("http"))
                BaseDA.getFilesInfor(_tmpFileIds).then(res => {
                    if (res?.length) methodsChild.setValue("_files", [..._fileId, ...res.map(e => { return { ...e, type: e.type ? e.type : [".png", ".svg", ".jpg"].some(t => e.name.endsWith(t)) ? "image" : "file" } })])
                })
            }
            rels.forEach((_rel) => {
                const _relItems = methodsChild.getValues(_rel.Column) ?? []
                let _relIds = []
                res.data.map(e => e[_rel.Column]?.split(",") ?? []).flat(Infinity).forEach(id => {
                    if (!_relIds.includes(id)) _relIds.push(id)
                })
                _relIds = _relIds.filter(id => _relItems.every(e => e.Id !== id))
                if (_relIds.length) {
                    const _relDataCotroller = new DataController({ pid: searchParams.get("pid"), module: _rel.TablePK })
                    _relDataCotroller.getByListId(_relIds).then(res => {
                        if (res.code === 200) methodsChild.setValue(_rel.Column, [..._relItems, ...res.data.filter(e => e != undefined)])
                    })
                }
            })
            setData(page ? [...data, ...res.data] : res.data)
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (isOpen && totalCount !== data.length) getChildren()
    }, [totalCount])

    return <>
        <div className={`row ${styles['table-row']}`}>
            {
                cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                    const { _value, _minW } = getTableConfig(_col, item)
                    if (_col.DataType == FEDataType.FILE && item[_col.Name] && methods.watch("_files")?.length) {
                        const _files = methods.getValues("_files")
                        var _fileInfor = _files.find(e => e.id === item[_col.Name])
                        var _maxWidth = '28rem'
                    }
                    if (i > 0) {
                        return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort, maxWidth: _maxWidth }}>
                            {
                                _fileInfor ? <NavLink to={ConfigApi.imgUrlId + _fileInfor.id} target='_blank' style={{ width: "100%" }}>
                                    {_fileInfor.type === "image" ? <img alt={_fileInfor.name} src={ConfigApi.imgUrlId + _fileInfor.id} style={{ width: "100%" }} /> : <Text maxLine={1} style={{ color: "var(--primary-main-color)" }}>{_fileInfor.name}</Text>}
                                </NavLink> :
                                    typeof _value === "string" || typeof _value === "number" ?
                                        <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text> :
                                        _value
                            }
                        </div>
                    } else {
                        return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }}>
                            <Checkbox size={'1.6rem'} value={checkValue}
                                onChange={(v) => {
                                    const newList = selected.filter(id => id !== item.Id && id !== item.ParentId)
                                    if (v) newList.push(item.Id)
                                    setSelected(newList)
                                }} />
                            <div style={{ paddingLeft: `${space}rem` }}>
                                {totalCount ? <button type='button' className='row icon-button20' onClick={() => {
                                    if (!isOpen && !data.length) getChildren()
                                    setIsOpen(!isOpen)
                                }}>
                                    <Winicon src={isOpen ? "fill/arrows/triangle-down" : "fill/arrows/triangle-right"} size={"1.2rem"} />
                                </button> : <div style={{ width: '2rem' }} />}
                            </div>
                            {typeof _value === "string" || typeof _value === "number" ?
                                <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text> :
                                _value}
                        </div>
                    }
                })
            }
            {
                rels.filter((e) => !e.Setting.IsHidden).map((_rel) => {
                    const _ids = item[_rel.Column]?.split(",") ?? []
                    const _mapValue = _ids.length ? (methods.watch(_rel.Column) ?? []).filter(e => _ids.includes(e.Id)) : undefined
                    return <div key={`${_rel.Id}-${index}`} className={`row ${_rel.Id}`} style={{ width: _rel.Setting?.Width ?? "20rem", order: _rel.Setting.Sort }}>
                        <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_mapValue?.map(e => e.Name ?? "-")?.join(",") ?? ""}</Text>
                    </div>
                })
            }
            <div key={'blast-' + index} className='row' style={{ gap: '0.8rem', justifyContent: 'center', order: 100 }}>
                {selected.length === 0 || selected.includes(item.Id) ? <>
                    <button type='button' className='row icon-button20' onClick={() => { showPopupAddEdit(item) }}><Winicon src='fill/user interface/d-edit' /></button>
                    {showPopupAction ? <button type='button' className='row icon-button20' onClick={(ev) => { showPopupAction(ev, item) }}><Winicon src='fill/text/menu-dots' style={{ rotate: "90deg" }} /></button> : null}
                    {onDelete ? <button type='button' className='row icon-button20' onClick={(ev) => { onDelete(item) }}><Winicon src='fill/user interface/trash-can' /></button> : null}
                </> : null}
            </div>
        </div>
        {
            isOpen ? <>
                {data.map((child, i) => <TableRow
                    key={`${child.Id}-${i}`}
                    index={i}
                    item={child}
                    cols={cols}
                    rels={rels}
                    methods={methodsChild}
                    selected={selected.includes(item.Id) ? [...selected, ...data.map(e => e.Id)] : selected}
                    dataController={dataController}
                    setSelected={setSelected}
                    showPopupAddEdit={showPopupAddEdit}
                    showPopupAction={showPopupAction}
                    onDelete={onDelete}
                    space={space + 1.6}
                />)}
                {totalCount > data.length ? <Button
                    label={`See more ${totalCount} items`}
                    onClick={() => { getChildren(Math.floor(data.length / 10) + 1) }}
                    style={{ position: "sticky", left: `${8.4 + space}rem`, width: "fit-content", padding: "0.4rem 0.8rem", color: "var(--primary-main-color)", margin: "0.8rem" }}
                /> : null}
                <div style={{ backgroundColor: 'var(--neutral-bolder-background-color)', width: "100%", height: 1, position: "sticky", left: 0 }} />
            </> : null
        }
    </>
}