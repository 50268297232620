import './button.module.css'
import { onSelectedLayer } from "../pageView"
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import React, { useEffect, useState } from 'react'
import { PageActions } from '../reducer'
import { OutlineRadius4Angle, OutlineRadiusAll } from '../../../../assets/icon'
import { ColorPicker } from './functions/funtions'
import { Ultis } from '../../../../Utils'
import { DesignTokenType } from '../../../home/designToken/da'

export default function SettingCheckBox({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const designToken = useSelector((store) => store.designToken.data)
    const [hasDesignToken, setHasDesignToken] = useState(false)

    const [radiusAll, setRadiusAll] = useState(true)
    const _iframe = document.getElementById("iframe-render-page-view")

    const updateStyle = (style) => {
        PageActions.updateLayerAll(dispatch, layers.map(e => {
            if (e.Id === selectedLayer.Id) {
                let _newSettingStyle = { ...e.Setting.style, ...style }
                for (const prop in _newSettingStyle) {
                    if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
                }
                return { ...e, Setting: { ...e.Setting, style: _newSettingStyle } }
            }
            else return e
        }))
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)

            if (_newElement) {
                const _newStyle = window.getComputedStyle(_newElement)
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))

                // const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                // methods.setValue('background-color-value', _bgColor.substring(0, 7))
                // methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                // methods.setValue('opacity', parseFloat(_newStyle.opacity))

                // const checkColor = selectedLayer.Setting.checkColor ?? "#FFFFFFFF"
                // methods.setValue('check-color-value', checkColor.substring(0, 7))
                // methods.setValue('check-color-opacity', `${Ultis.hexToPercent(checkColor.substring(7))}`)

                if (selectedLayer.Setting.checkColor?.startsWith("var")) {
                    const _textColor = Ultis.rgbToHex(_newStyle.color)
                    methods.setValue('check-color-value', _textColor.substring(0, 7))
                    methods.setValue('check-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                    methods.setValue('check-color-id', selectedLayer.Setting.textColor?.replace("var(--", "").replace(")", ""))
                    //setHasDesignToken(true)
                } else {
                    const _textColor = Ultis.rgbToHex(_newStyle.color)
                    methods.setValue('check-color-value', _textColor.substring(0, 7))
                    methods.setValue('check-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                }

                if (selectedLayer.Setting.style?.backgroundColor?.startsWith("var")) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', selectedLayer.Setting.style?.backgroundColor.replace("var(--", "").replace(")", ""))
                } else if (selectedLayer.Setting.style?.backgroundColor) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', undefined)
                } else {
                    methods.setValue('background-color-id', undefined)
                    methods.setValue('background-color-value', undefined)
                }

                if (selectedLayer.Setting.size.includes("px")) {
                    methods.setValue('size', selectedLayer.Setting.size.replace("px", ""))
                    methods.setValue('size-unit', "px")
                } else {
                    methods.setValue('size', selectedLayer.Setting.size.replace("rem", ""))
                    methods.setValue('size-unit', "rem")
                }

                if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                    if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("px")) {
                        var _vTL = _newStyle['border-top-left-radius'].replace("px", "")
                        var _vUnitTL = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("%")) {
                        _vTL = parseFloat(_newStyle['border-top-left-radius'].replace("%", ""))
                        _vUnitTL = '%'
                    } else {
                        _vTL = (parseFloat(_newStyle['border-top-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("px")) {
                        var _vTR = _newStyle['border-top-right-radius'].replace("px", "")
                        var _vUnitTR = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("%")) {
                        _vTR = parseFloat(_newStyle['border-top-right-radius'].replace("%", ""))
                        _vUnitTR = '%'
                    } else {
                        _vTR = (parseFloat(_newStyle['border-top-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTR = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("px")) {
                        var _vBL = _newStyle['border-bottom-left-radius'].replace("px", "")
                        var _vUnitBL = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("%")) {
                        _vBL = parseFloat(_newStyle['border-bottom-left-radius'].replace("%", ""))
                        _vUnitBL = '%'
                    } else {
                        _vBL = (parseFloat(_newStyle['border-bottom-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("px")) {
                        var _vBR = _newStyle['border-bottom-right-radius'].replace("px", "")
                        var _vUnitBR = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("%")) {
                        _vBR = parseFloat(_newStyle['border-bottom-right-radius'].replace("%", ""))
                        _vUnitBR = '%'
                    } else {
                        _vBR = (parseFloat(_newStyle['border-bottom-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBR = "rem"
                    }
                    methods.setValue('border-top-left-radius', _vTL)
                    methods.setValue('border-top-left-radius-unit', _vUnitTL)
                    methods.setValue('border-top-right-radius', _vTR)
                    methods.setValue('border-top-right-radius-unit', _vUnitTR)
                    methods.setValue('border-bottom-left-radius', _vBL)
                    methods.setValue('border-bottom-left-radius-unit', _vUnitBL)
                    methods.setValue('border-bottom-right-radius', _vBR)
                    methods.setValue('border-bottom-right-radius-unit', _vUnitBR)
                    methods.setValue('border-radius', _vTL)
                    methods.setValue('border-radius-unit', _vUnitTL)
                    if (radiusAll) setRadiusAll(false)
                } else {
                    if (selectedLayer.Setting.style?.borderRadius?.includes("px")) {
                        var _v = _newStyle['border-radius'].replace("px", "")
                        var _vUnit = 'px'
                    } else if (selectedLayer.Setting.style?.borderRadius?.includes("%")) {
                        _v = parseFloat(_newStyle['border-radius'].replace("%", ""))
                        _vUnit = '%'
                    } else {
                        _v = (parseFloat(_newStyle['border-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnit = "rem"
                    }
                    methods.setValue('border-radius', _v)
                    methods.setValue('border-radius-unit', _vUnit)
                    methods.setValue('border-top-left-radius', _v)
                    methods.setValue('border-top-left-radius-unit', _vUnit)
                    methods.setValue('border-top-right-radius', _v)
                    methods.setValue('border-top-right-radius-unit', _vUnit)
                    methods.setValue('border-bottom-left-radius', _v)
                    methods.setValue('border-bottom-left-radius-unit', _vUnit)
                    methods.setValue('border-bottom-right-radius', _v)
                    methods.setValue('border-bottom-right-radius-unit', _vUnit)
                    if (!radiusAll) setRadiusAll(true)
                }

            }
        }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/user interface/menu-8"} /></button>
        </div>

        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Size</Text>
                    <TextField
                        className="regular1"
                        name="size"
                        register={methods.register('size', {
                            onBlur: (ev) => {
                                PageActions.updateLayerAll(dispatch, layers.map(e => {
                                    if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: ev.target.value.trim() + methods.getValues('size-unit') } }
                                    else return e
                                }))
                            }
                        })}
                        defaultValue={methods.getValues('size')}
                        placeholder="size"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('size-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('size-unit')
                                if (_tmp !== v.id) {
                                    PageActions.updateLayerAll(dispatch, layers.map(e => {
                                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: v.id === 'rem' ? `${parseFloat(methods.getValues('size')) / 10}rem` : `${parseFloat(methods.getValues('size')) * 10}${v.id}` } }
                                        else return e
                                    }))
                                }
                            }}
                        />}
                    />
                </div>
            </div>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <div className="row" style={{ gap: '0.8rem' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border radius</Text>
                    <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                            <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                        </button>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                            <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                        </button>
                    </div>
                </div>
                {radiusAll ? <TextField
                    className="regular1"
                    name="border-radius"
                    register={methods.register('border-radius', { onBlur: (ev) => { updateStyle({ borderRadius: ev.target.value.trim() + methods.getValues('border-radius-unit') }) } })}
                    placeholder="Border radius"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('border-radius-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('border-radius-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    borderRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-radius'))}${v.id}`,
                                    borderTopLeftRadius: undefined,
                                    borderBottomLeftRadius: undefined,
                                    borderTopRightRadius: undefined,
                                    borderBottomRightRadius: undefined,
                                })
                            }
                        }}
                    />}
                /> : <div className="row" style={{ padding: '0.4rem 0', gap: '0.8rem', height: '8rem' }}>
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-left-radius"
                            register={methods.register('border-top-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopLeftRadius: ev.target.value.trim() + methods.getValues('border-top-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TL radius"
                            type="number"
                            suffix={<Controller
                                name={'border-top-left-radius-unit'}
                                control={methods.control}
                                render={({ field }) => <Select1
                                    readOnly
                                    className="regular1"
                                    popupClassName="select-unit-options"
                                    value={field.value}
                                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                    onChange={(v) => {
                                        const _tmp = methods.getValues('border-top-left-radius-unit')
                                        if (_tmp !== v.id) {
                                            updateStyle({
                                                borderRadius: undefined,
                                                borderTopLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-left-radius'))}${v.id}`
                                            })
                                        }
                                    }}
                                />}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-left-radius"
                            register={methods.register('border-bottom-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: ev.target.value.trim() + methods.getValues('border-bottom-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BL radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-left-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />
                    </div>
                    <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-right-radius"
                            register={methods.register('border-top-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopRightRadius: ev.target.value.trim() + methods.getValues('border-top-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-top-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-right-radius"
                            register={methods.register('border-bottom-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: ev.target.value.trim() + methods.getValues('border-bottom-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                    </div>
                </div>}
            </div>
        </div>

        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setHasDesignToken(!hasDesignToken) }}><Winicon src={"fill/business/coins"} /></button>
                <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
            </div>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.8rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {hasDesignToken && designToken?.length > 0 ?
                <Select1
                    className="regular1"
                    placeholder={`Design Color`}
                    value={methods.watch('background-color-id')}
                    options={designToken.map((el) => {
                        return {
                            id: el.Id,
                            name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                }}></div> | <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                }}></div>
                                <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                            </div>,
                            disabled: el.Type === DesignTokenType.group
                        }
                    })}
                    style={{}}
                    onChange={(v) => {
                        const _tmp = methods.getValues('background-color-id')
                        if (_tmp !== v.id) {
                            updateStyle({ backgroundColor: `var(--${v.id})` })
                        }
                    }}
                /> :
                methods.watch("background-color-value") ? <ColorPicker
                    colorName={'background-color-value'}
                    colorOpcaityName={'background-color-opacity'}
                    style={{ width: '100%', height: 28, }}
                    register={methods.register}
                    colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                    opacity={methods.watch('opacity')}
                    onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity })}
                    onDelete={() => updateStyle({ backgroundColor: undefined, opacity: undefined })}
                /> : null}  
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Check Color</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.8rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {/* {methods.watch("check-color-value") ? <ColorPicker
                colorName={'check-color-value'}
                colorOpcaityName={'check-color-opacity'}
                register={methods.register}
                colorValue={methods.watch("check-color-value") + Ultis.percentToHex(parseFloat(methods.watch('check-color-opacity')))}
                onChange={({ colorValue, opacity }) => PageActions.updateLayerAll(dispatch, layers.map(e => {
                    if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, checkColor: colorValue } }
                    else return e
                }))}
            /> : null} */}
            {hasDesignToken && designToken?.length > 0 ?
                <Select1
                    className="regular1"
                    placeholder={`Design Color`}
                    value={methods.watch('check-color-id')}
                    options={designToken.map((el) => {
                        return {
                            id: el.Id,
                            name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                }}></div> | <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                }}></div>
                                <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                            </div>,
                            disabled: el.Type === DesignTokenType.group
                        }
                    })}
                    style={{}}
                    onChange={(v) => {
                        const _tmp = methods.getValues('check-color-id')
                        if (_tmp !== v.id) {
                            debugger
                            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting , checkColor: `var(--${v.id})` }}])
                        }
                    }}
                /> :
                methods.watch("check-color-value") ? <ColorPicker
                    colorName={'check-color-value'}
                    colorOpcaityName={'check-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("check-color-value") + Ultis.percentToHex(parseFloat(methods.watch('check-color-opacity')))}
                    onChange={({ colorValue, opacity }) => PageActions.updateLayerAll(dispatch, layers.map(e => {
                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, checkColor: colorValue } }
                        else return e
                    }))}
            /> : null}  
        </div>
    </>
}