import { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, closePopup } from "wini-web-components";
import { FEDataType } from "../../home/table/da";
import { RangeForm } from "../../../project-component/component-form";
import { Ultis } from "../../../Utils";
import { RenderComponentByType } from "../config";

const PopupFormFilter = forwardRef(function PopupFormFilter(data, ref) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const [_cols, setCols] = useState([])

    const onSubmit = (ev) => {
        closePopup(ref)
        const params = new URLSearchParams();
        for (const key in ev) {
            if (ev[key] !== undefined && ev[key] !== null) {
                if (typeof ev[key] === "string" && !ev[key].length) break;
                const _col = _cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""));
                if (_col) {
                    switch (_col?.DataType) {
                        case FEDataType.NUMBER:
                            params.append(key, typeof ev[key] === 'string' ? parseFloat(ev[key]) : ev[key])
                            break;
                        case FEDataType.DATE:
                            params.append(key, Ultis.stringToDate(ev[key]).getTime())
                            break;
                        case FEDataType.DATETIME:
                            params.append(key, Ultis.stringToDate(ev[key], 'dd/mm/yyyy hh:mm:ss').getTime())
                            break;
                        case FEDataType.MONEY:
                            params.append(key, parseInt(ev[key].replaceAll(',', '')))
                            break;
                        default:
                            params.append(key, ev[key])
                            break;
                    }
                } else params.append(key, ev[key])
            }
        }
        navigate('?' + params.toString());
    }

    useEffect(() => {
        const _setCols = data.cols.filter(e => e.Name !== "Id" && !e.Query && ![FEDataType.FILE, FEDataType.GID, FEDataType.HTML, FEDataType.PASSWORD, FEDataType.STRING].includes(e.DataType))
        for (const [key, value] of searchParams) {
            const _col = _setCols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
            if (_col) {
                switch (_col.DataType) {
                    case FEDataType.NUMBER:
                        methods.setValue(key, typeof value === 'string' ? parseFloat(value) : value)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(key, Ultis.money(value))
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(key, value == true)
                        break;
                    default:
                        methods.setValue(key, value)
                        break;
                }
            } else methods.setValue(key, value)
        }
        setCols(_setCols)
    }, [])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '2rem', overflow: 'hidden auto' }}>
            {_cols.map((e) => {
                if ([FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(e.DataType) && !e.Form?.Options?.length) {
                    return <RangeForm
                        key={e.Id}
                        className={'row'}
                        labelStyle={{ width: "16rem" }}
                        type={e.DataType === FEDataType.DATE ? 'date' : e.DataType === FEDataType.DATETIME ? 'date-time' : e.DataType === FEDataType.MONEY ? 'money' : 'number'}
                        startName={`${e.Name}_min`}
                        endName={`${e.Name}_max`}
                        control={methods.control}
                        label={e.Setting.Title}
                        errors={methods.formState.errors}
                        getValues={methods.getValues}
                        style={{ order: e.Form.Sort }}
                    />
                } else return <RenderComponentByType label={e.Setting.Title} key={e.Id} fieldItem={{ ...e, Form: { ...e.Form, Required: false } }} methods={methods} style={{ order: e.Form.Sort }} className={'row'} labelStyle={{ width: "16rem" }} />
            })}
        </div>
        <div className="row popup-footer">
            <button type="button" className="row button-grey" onClick={() => {
                _cols.forEach(c => {
                    methods.setValue(c.Name, null)
                })
            }}>
                <Text className="button-text-3">Reset</Text>
            </button>
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(onSubmit)}>
                <Text className="button-text-3">Apply</Text>
            </button>
        </div>
    </form>
})

export default PopupFormFilter