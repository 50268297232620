import { useEffect, useRef } from "react"
import styles from '../../componentProperties/functions/funtions.module.css'
import { Text, TextField, Winicon } from "wini-web-components"
import { Ultis } from "../../../../../Utils"

export const ColorPicker = ({ colorName, onDelete, style, colorOpcaityName, register, colorValue = '', opacity, onChange }) => {
    const colorPickerRef = useRef()

    useEffect(() => {
        const _colorPicker = colorPickerRef.current?.querySelector('input[type="color"]')
        if (colorValue && _colorPicker) _colorPicker.value = colorValue.substring(0, 7)
    }, [colorValue])

    return <div ref={colorPickerRef} className={`row ${styles['color-picker-container']}`} style={{ gap: '1.2rem', }}>
        <TextField
            className="regular1"
            name={colorName}
            register={register(colorName, {
                onBlur: (ev) => {
                    const newVl = ev.target.value.replaceAll("#", "").substring(0, 6)
                    const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                    onChange({ colorValue: `#${newVl}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                }
            })}
            style={{ flex: 1, ...style }}
            placeholder={colorName}
            maxLength={7}
            prefix={<label className={styles['color-picker']} style={{ backgroundColor: colorValue }}>
                <input
                    type='color'
                    style={{ visibility: 'hidden' }}
                    onChange={(ev) => {
                        const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                        onChange({ colorValue: `${ev.target.value}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                    }}
                />
            </label>}
            suffix={<>
                <input
                    name={colorOpcaityName}
                    type='number'
                    className={styles['color-opacity']}
                    {...register(colorOpcaityName, {
                        onBlur: (ev) => {
                            let _vl = parseFloat(ev.target.value)
                            if (isNaN(_vl)) _vl = 10
                            const _colorValue = colorPickerRef.current?.querySelector('input[type="color"]').value
                            onChange({ colorValue: `${_colorValue}${Ultis.percentToHex(_vl)}`, opacity: opacity })
                        }
                    })}
                />
                <Text className='regular1'>%</Text>
            </>}
        />
        {opacity != undefined &&
            <button type='button' className='row icon-button24' onClick={() => { onChange({ colorValue: colorValue, opacity: opacity ? 0 : 1 }) }}>
                <Winicon src={`outline/user interface/${opacity ? "view" : "hide"}`} sizee='1.2rem' />
            </button>}

        {onDelete &&
            <button type='button' className='row icon-button24' onClick={onDelete}>
                <Winicon src={"fill/user interface/e-delete"} size='1.2rem' />
            </button>}
    </div>
}

export const listBreakpoint = ['remain', 'col0', 'col1', 'col2', 'col3', 'col4', 'col6', 'col8', 'col10', 'col12', 'col16', 'col18', 'col20', 'col24'];
export const tilteBreakpoints = ['MIN', 'SM', 'MD', 'LG', 'XL', 'XXL'];
// Function to extract the size, identifier (brp), and value from each string
export const getSizeValue = (str) => {
    // Loop through each size identifier
    for (let brp of tilteBreakpoints) {
        const lowerCaseBrp = brp.toLowerCase();
        // Check if the string contains the size identifier
        if (str.includes(lowerCaseBrp)) {
            if (str.split("-")[1] === "xxl") {
                return { value: str, brp: "XXL" };
            }
            return { value: str, brp };
        }
    }
    // Return null if no size identifier is found
    return null;
};