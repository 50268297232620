import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { PageActions } from "../reducer"
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import { useEffect, useState } from "react"
import { Ultis } from "../../../../Utils"
import { ColorPicker } from "./functions/funtions"
import { FilledFontSize, FilledLetterSpacing, FilledLineHeight, OutlineRadius4Angle } from "../../../../assets/icon"
import { OnChangeWH } from "./functions/common"

export default function SettingText({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    const [position, setPosition] = useState(false)
    const tiltePositionss = ['static', 'relative', 'fixed', 'absolute', 'sticky'];


    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return undefined
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)
            if (!_newElement) return undefined
            const _newStyle = window.getComputedStyle(_newElement)
            if (_newElement) {
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                methods.setValue('font-family', _newStyle['font-family'])
                methods.setValue('font-weight', _newStyle['font-weight'])
                methods.setValue('font-size', selectedLayer.Setting.style?.fontSize?.includes("px") ? _newStyle['font-size'].replace("px", "") : (parseFloat(_newStyle['font-size'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('font-size-unit', selectedLayer.Setting.style?.fontSize?.includes("px") ? "px" : "rem")
                methods.setValue('line-height', selectedLayer.Setting.style?.lineHeight?.includes("px") ? parseFloat(_newStyle['line-height'].replace("px", "")).toFixed(1).replace(".0", "") : _newStyle['line-height'] === "normal" ? "normal" : (parseFloat(_newStyle['line-height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('line-height-unit', selectedLayer.Setting.style?.lineHeight?.includes("px") ? "px" : "rem")
                methods.setValue('letter-spacing', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? _newStyle['letter-spacing'].replace("px", "") : _newStyle['letter-spacing'] === "normal" ? "normal" : (parseFloat(_newStyle['letter-spacing'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('letter-spacing-unit', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? "px" : "rem")
                const _textColor = Ultis.rgbToHex(_newStyle.color)
                methods.setValue('text-color', _textColor.substring(0, 7))
                methods.setValue('text-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                if (selectedLayer.Setting.style?.position) {
                    if (selectedLayer.Setting.style?.position !== "static") {
                        if (['top', 'right', 'bottom', 'left'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                            if (selectedLayer.Setting.style?.top?.includes("px")) {
                                var _top = _newStyle['top'].replace("px", "")
                                var _topUnit = 'px'
                            } else if (selectedLayer.Setting.style?.top?.includes("%")) {
                                _top = parseFloat(_newStyle['top'].replace("%", ""))
                                _topUnit = '%'
                            } else {
                                _top = (parseFloat(_newStyle['top'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _topUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.right?.includes("px")) {
                                var _right = _newStyle['right']?.replace("px", "")
                                var _rightUnit = 'px'
                            } else if (selectedLayer.Setting.style?.right?.includes("%")) {
                                _right = parseFloat(_newStyle['right']?.replace("%", ""))
                                _rightUnit = '%'
                            } else {
                                _right = (parseFloat(_newStyle['right']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _rightUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.bottom?.includes("px")) {
                                var _bottom = _newStyle['bottom']?.replace("px", "")
                                var _bottomUnit = 'px'
                            } else if (selectedLayer.Setting.style?.bottom?.includes("%")) {
                                _bottom = parseFloat(_newStyle['bottom']?.replace("%", ""))
                                _bottomUnit = '%'
                            } else {
                                _bottom = (parseFloat(_newStyle['bottom']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _bottomUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.left?.includes("px")) {
                                var _left = _newStyle['left']?.replace("px", "")
                                var _leftUnit = 'px'
                            } else if (selectedLayer.Setting.style?.left?.includes("%")) {
                                _left = parseFloat(_newStyle['left']?.replace("%", ""))
                                _leftUnit = '%'
                            } else {
                                _left = (parseFloat(_newStyle['left']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _leftUnit = "rem"
                            }
                            methods.setValue('position-top', _top)
                            methods.setValue('position-top-unit', _topUnit)
                            methods.setValue('position-right', _right)
                            methods.setValue('position-right-unit', _rightUnit)
                            methods.setValue('position-bottom', _bottom)
                            methods.setValue('position-bottom-unit', _bottomUnit)
                            methods.setValue('position-left', _left)
                            methods.setValue('position-left-unit', _leftUnit)
                        }
                        methods.setValue('position-style', selectedLayer.Setting.style.position)
                        setPosition(true)
                    } else {
                        methods.setValue('position-style', "static")
                        methods.setValue('position-top-unit', "rem")
                        methods.setValue('position-right-unit', "rem")
                        methods.setValue('position-bottom-unit', "rem")
                        methods.setValue('position-left-unit', "rem")
                        methods.setValue('position-top', undefined)
                        methods.setValue('position-right', undefined)
                        methods.setValue('position-bottom', undefined)
                        methods.setValue('position-left', undefined)
                        setPosition(false)
                    }

                } else {
                    methods.setValue('position-style', "static")
                    methods.setValue('position-top-unit', "rem")
                    methods.setValue('position-right-unit', "rem")
                    methods.setValue('position-bottom-unit', "rem")
                    methods.setValue('position-left-unit', "rem")
                    methods.setValue('position-top', undefined)
                    methods.setValue('position-right', undefined)
                    methods.setValue('position-bottom', undefined)
                    methods.setValue('position-left', undefined)

                    setPosition(false)
                }

            }
        }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/user interface/menu-8"} /></button>
        </div>
        <div className="col" style={{ padding: '0.6rem 1.2rem', borderBottom: '1px solid #444444' }}>
            <TextField
                key={'textfield-' + selectedLayer.Id}
                className="regular1"
                defaultValue={selectedLayer.Name}
                placeholder="Type something..."
                onComplete={(ev) => ev.target.blur()}
                onBlur={(ev) => {
                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, value: ev.target.value } }])
                }}
            />
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Font</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
        </div>
        <OnChangeWH
            methods={methods}
            onChange={updateStyle}
            selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer?.Id)}
            selectedLayer={selectedLayer}
        />
        <div className="col" style={{ padding: '0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <Select1
                readOnly
                placeholder={'Font family'}
                className="regular1"
                value={methods.watch('font-family')}
                options={['Inter', 'Arial', 'Times New Roman', 'Source Serif 4'].map(e => {
                    return {
                        id: `"${e}"`,
                        name: e
                    }
                })}
                onChange={(v) => { updateStyle({ fontFamily: v.id }) }}
            />
            <div className="row" style={{ gap: '0.8rem' }}>
                <Select1
                    readOnly
                    placeholder={'Font weight'}
                    className="regular1"
                    style={{ flex: 1 }}
                    value={methods.watch('font-weight')}
                    options={['200', '400', '600', '800'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(v) => { updateStyle({ fontWeight: v.id }) }}
                />
                <TextField
                    className="regular1"
                    name="font-size"
                    prefix={<FilledFontSize color="var(--neutral-text-title-color)" size={"1.4rem"} />}
                    register={methods.register('font-size', {
                        onBlur: (ev) => { updateStyle({ fontSize: ev.target.value.trim() + methods.getValues('font-size-unit') }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Font size"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('font-size-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('font-size-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('font-size-unit', v.id)
                                updateStyle({ fontSize: v.id === 'rem' ? `${parseFloat(methods.getValues('font-size')) / 10}rem` : `${parseFloat(methods.getValues('font-size')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    className="regular1"
                    name="line-height"
                    register={methods.register('line-height', {
                        onBlur: (ev) => { updateStyle({ lineHeight: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('line-height-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Line height"
                    prefix={<FilledLineHeight color="var(--neutral-text-title-color)" size={"1.4rem"} />}
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('line-height') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('line-height-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--neutral-disable-background-color': '#00000000' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('line-height-unit')
                            if (_tmp !== v.id) {
                                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                                switch (v.id) {
                                    case 'rem':
                                        updateStyle({ lineHeight: `${parseFloat(methods.getValues('line-height')) / _rootFz}rem` })
                                        break;
                                    case 'px':
                                        updateStyle({ lineHeight: `${parseFloat(methods.getValues('line-height')) * _rootFz}px` })
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }}
                    />}
                />
                <TextField
                    className="regular1"
                    name="letter-spacing"
                    register={methods.register('letter-spacing', {
                        onBlur: (ev) => { updateStyle({ letterSpacing: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('letter-spacing-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Letter spacing"
                    prefix={<FilledLetterSpacing color="var(--neutral-text-title-color)" size={"1.4rem"} />}
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('letter-spacing') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('letter-spacing-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--neutral-disable-background-color': 'transparent' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('letter-spacing-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('letter-spacing-unit', v.id)
                                updateStyle({ letterSpacing: v.id === 'rem' ? `${parseFloat(methods.getValues('letter-spacing')) / 10}rem` : `${parseFloat(methods.getValues('letter-spacing')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="col" style={{ gap: '0.8rem' }}>
                {methods.watch("text-color") ? <ColorPicker
                    colorName={'text-color'}
                    colorOpcaityName={'text-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("text-color") + Ultis.percentToHex(parseFloat(methods.watch('text-color-opacity')))}
                    onChange={({ colorValue, opacity }) =>
                        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, color: colorValue } } }])
                    }
                /> : null}
            </div>
            <div className="col" style={{ gap: '0.4rem', }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Position style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="position-style"
                    value={methods.watch('position-style')}
                    options={tiltePositionss.map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('position-style')
                        if (_tmp !== ev.id) {
                            updateStyle({ position: ev.id })
                        }
                    }}
                />
                {
                    position ?
                        <div className="row" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Top</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}
                                    className="regular1"
                                    name="position-top"
                                    register={methods.register('position-top', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                top: ev.target.value.trim() + methods.getValues('position-top-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="top"
                                    type="number"
                                    suffix={<Controller
                                        name={'position-top-unit'}
                                        control={methods.control}
                                        render={({ field }) => <Select1
                                            readOnly
                                            className="regular1"
                                            popupClassName="select-unit-options"
                                            value={field.value}
                                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                            onChange={(v) => {
                                                const _tmp = methods.getValues('position-top-unit')
                                                if (_tmp !== v.id) {
                                                    updateStyle({
                                                        position: methods.getValues('position-style'),
                                                        top: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-top')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-top')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-top'))}${v.id}`}`
                                                    })
                                                }
                                            }}
                                        />}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Right</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}
                                    className="regular1"
                                    name="position-right"
                                    register={methods.register('position-right', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                right: ev.target.value.trim() + methods.getValues('position-right-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="right"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-right-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-right-unit')
                                            if (_tmp !== v.id) {
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    right: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-right')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-right')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-right'))}${v.id}`}`
                                                })
                                            }
                                        }}
                                    />}
                                />
                            </div>
                            <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Bottom</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}
                                    className="regular1"
                                    name="position-bottom"
                                    register={methods.register('position-bottom', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                bottom: ev.target.value.trim() + methods.getValues('position-bottom-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="bottom"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-bottom-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-bottom-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    bottom: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-bottom')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-bottom')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-bottom'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Left</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}
                                    className="regular1"
                                    name="position-left"
                                    register={methods.register('position-left', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                left: ev.target.value.trim() + methods.getValues('position-left-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="left"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-left-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-left-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    left: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-left')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-left')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-left'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                            </div>
                        </div> : <></>
                }
            </div>

        </div>
    </>
}