import styles from './view.module.css'
import { forwardRef, useState } from "react"
import SettingFormTab from "./form/settingForm"
import SettingChartTab from "./settingChart"
import SettingReportTab from "./settingReport"
import { closePopup, Text, Winicon } from 'wini-web-components'
import SettingCardTab from './card/settingCard'

const PopupSettingAssets = forwardRef(function PopupSettingAssets(data, ref) {
    const [tab, setTab] = useState(0)

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <SettingFormTab
                    pid={data.pid}
                    item={data.item}
                />
            case 1:
                return <SettingCardTab
                    pid={data.pid}
                    item={data.item}
                />
            case 2:
                return <SettingChartTab
                    item={data.item}
                    pid={data.pid}
                />
            case 3:
                return <SettingReportTab
                    item={data.item}
                    pid={data.pid}
                />
            default:
                return <div />
        }
    }

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className='row popup-header' style={{ gap: '0.8rem', padding: '0.8rem', paddingLeft: '2.3rem', borderColor: "#d7d7db" }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.item?.Name ?? ""} table</Text>
            <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><Winicon src={"fill/user interface/e-remove"} size="2.4rem" /></button>
        </div>
        <div className={`row ${styles['tab-setting-container']}`}>
            <button type='button' className={`row label-4 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Form</button>
            <button type='button' className={`row label-4 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Card</button>
            <button type='button' className={`row label-4 ${tab === 2 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(2) }}>Chart</button>
            <button type='button' className={`row label-4 ${tab === 3 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(3) }}>Report</button>
        </div>
        {renderTabView()}
    </div>
})

export default PopupSettingAssets
