import { Button, Text } from "wini-web-components"
import { ComponentType } from "../da"

export default function RenderCard({ layers = [] }) {
    const renderCardByLayer = (item) => {
        const children = layers.filter(e => e.ParentId === item.Id)
        let _props = { ...item.Setting }
        if (!item.PageId) _props['layout-id'] = item.LayoutId ?? item.Id
        _props.id = item.Id
        _props.className = _props.className ? (_props.className + " layer-item") : "layer-item"
        switch (item.Type) {
            case ComponentType.container:
                if (_props) _props['component-type'] = item.Type
                return <div key={item.Id} {...(_props ?? item.Setting)}>
                    {children.map(e => renderCardByLayer(e))}
                </div>
            case ComponentType.text:
                return <Text key={item.Id} {...(_props ?? item.Setting)}>{item.Setting?.value ?? ""}</Text>
            case ComponentType.button:
                return <Button key={item.Id} type="button"  {...(_props ?? item.Setting)} />
            case ComponentType.img:
                return <img key={item.Id} alt="" {...(_props ?? item.Setting)} />
            default:
                return <div key={item.Id} />
        }
    }

    return layers.filter(e => !e.ParentId).map(e => renderCardByLayer(e, layers))
}