import { ToastMessage } from 'wini-web-components'
import styles from '../assest.module.css'
import { DataController, SettingDataController } from '../../controller'
import { useEffect, useState } from 'react'
import { ComponentType } from '../../../home/table/da'
import CaptureElement from '../../../../project-component/captureElement'

export default function TbAssetsCard({ tbName = "" }) {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _settingDataController = new SettingDataController({ pid: _pid, setting: "card" })
    const _dataController = new DataController({ pid: _pid, module: tbName })
    const [cards, setCards] = useState({ data: [] })
    const [dataModel, setDataModel] = useState()
    const _iframe = document.getElementById("iframe-render-page-view")

    const getCards = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 10, query: `@TbName:{${tbName}}` })
        if (res.code === 200) {
            setCards({
                data: [...cards.data, ...res.data.map(e => { return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props } })],
                totalCount: res.totalCount
            })
        } else ToastMessage.errors(res.message)
    }

    useEffect(() => {
        if (dataModel) getCards()
    }, [dataModel])

    useEffect(() => {
        document.body.querySelector(".setting-page-sidebar.left").style.width = "448px"
        _dataController.getListSimple({ page: 1, size: 1 }).then(res => {
            if (res.code === 200) setDataModel(res.data[0])
        })
    }, [])

    return <div className='row' style={{ flexWrap: "wrap", padding: '0.8rem 1.2rem', gap: '1.2rem', width: "100%" }}>
        {cards.data.map((e) => {
            return <div key={e.Id} draggable className={`col col12 ${styles['card-option-demo']} ${styles['component-options']}`}
                onDragStart={(ev) => {
                    ev.dataTransfer.setData("component-id", e.Id)
                    ev.dataTransfer.setData("component-width", e.Type.includes("horizontal") ? '87.2rem' : '42.4rem')
                    ev.dataTransfer.setData("component-type", ComponentType.card)
                    _iframe.classList.add('dragging')
                }}
                onDragEnd={() => {
                    _iframe.classList.remove('dragging')
                    _iframe.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)?.remove()
                }}
            >
                <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1 }}>
                    {/* <RenderCardNewsByType
                        pid={_pid}
                        style={{ width: e.Type.includes("horizontal") ? '80rem' : '42.4rem', backgroundColor: "var(--neutral-absolute-background-color)", padding: '2.4rem', borderRadius: '0.8rem', border: "var(--neutral-bolder-border-color)" }}
                        cardItem={e}
                        data={dataModel}
                    /> */}
                </CaptureElement>
            </div>
        })}
    </div>
}