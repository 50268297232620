import { Button, closePopup, Popup, showPopup, Text, TextField, Winicon } from 'wini-web-components'
import styles from './index.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, useEffect, useRef } from 'react'
import { CustomerActions } from '../screen/home/customer/reducer'

export default function Header() {
    const user = useSelector((store) => store.customer.data)
    const dispatch = useDispatch()
    const ref = useRef()

    useEffect(() => {
        CustomerActions.getInfor(dispatch)
    }, [])

    const showUserActions = (ev) => {
        const _box = ev.target.getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            hideButtonClose: true,
            style: { top: `${_box.y + _box.height + 2}px`, right: `${document.body.offsetWidth - _box.right}px`, position: 'absolute', width: 'fit-content' },
            content: <PopupUserActions ref={ref} />
        })
    }

    return <div className={`row ${styles['header']}`}>
        <Popup ref={ref} />
        <button type='button' className='row icon-button24'>
            <Winicon src="fill/user interface/menu-6" size={'1.6rem'} />
        </button>
        <Button
            prefix={<Winicon src={"fill/user interface/c-add"} size={"1.4rem"} color='var(--primary-main-color)' />}
            label='Button'
            style={{ border: 'var(--neutral-bolder-border-color)', backgroundColor: "var(--neutral-absolute-background-color)", width: '9.4rem', borderRadius: '2.4rem' }}
        />
        <TextField
            prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
            className={`placeholder-2 ${styles['header-search']}`}
            placeholder='Search'
        />
        <div style={{ flex: 1 }} />
        <button type='button' className='row icon-button24'>
            <Winicon src='fill/user interface/bell' size={'1.6rem'} />
        </button>
        <button type='button' className='row icon-button24'>
            <Winicon src="fill/user interface/mail" size={'1.8rem'} />
        </button>
        <button type='button' className='row icon-button32'>
            <Winicon src='color/flags/vietnam' size={'2.8rem'} />
        </button>
        <button type='button' className='row' onClick={showUserActions}>
            <img src={user?.AvatarUrl ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ borderRadius: "50%", width: '3.2rem', height: '3.2rem' }} />
        </button>
    </div>
}

const PopupUserActions = forwardRef(function PopupUserActions(data, ref) {
    const user = useSelector((store) => store.customer.data)

    return <div className={`col popup-actions ${styles['account-actions']}`}>
        <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }}>
            <Winicon src='outline/users/user-c-frame' />
            <Text className='label-4'>Profile</Text>
        </button>
        <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }}>
            <Winicon src='outline/user interface/gear' />
            <Text className='label-4'>Setting</Text>
        </button>
        <div className='col divider' />
        <button type='button' onClick={() => {
            closePopup(ref)
            CustomerActions.logout()
        }} className='col' style={{ gap: '0.4rem', padding: '1rem 1.6rem' }}>
            <Text className='label-4' style={{ color: 'var(--error-main-color)' }}>Logout</Text>
            <Text className='subtitle-4' >{user?.Email ?? user?.Username ?? ''}</Text>
        </button>
    </div>
})