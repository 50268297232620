import styles from "./index.module.css"
import { useEffect, useRef, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { ListProject } from "../screen/home/project/view"
import CreateProject from "../screen/home/project/create"
import ModuleListView from "../screen/module/manager/view"
import { useDispatch, useSelector } from "react-redux"
import ActionSidebar from "./menu"
import SettingPageView from "../screen/module/page/view"
import ListTable from "../screen/home/table/view"
import { TableController } from "../screen/home/table/controller"
import { PageActions } from "../screen/module/page/reducer"
import RenderPageByLayers from "../screen/module/page/renderPageView"
import Header from './header'
import { DesignTokenActions } from "../screen/home/designToken/reducer"
import { DesignTokenType } from "../screen/home/designToken/da"
import { hashPassword } from "../screen/module/config"


export const Home = () => {
    return window.location.pathname === '/' ? <div></div> : <div className="row main-layout">
        <Routes>
            <Route
                path={'/pages/:pName'}
                element={<SettingPageView />}
                exact
            />
            <Route
                path={'/home'}
                element={<MainRoute body={<ListProject />} />}
                exact
            />
            <Route
                path={'/admin'}
                element={<MainRoute body={<ListTable />} />}
                exact
            />
            <Route
                path={'/home/project'}
                element={<MainRoute body={<CreateProject />} />}
                exact
            />
            <Route
                path={'/manager/:pModuleName?/:moduleName'}
                element={<MainRoute body={<ManagerView />} />}
                exact
            />
            <Route
                path={'/page/:pageName'}
                element={<MainRoute body={<PageView />} />}
                exact
            />
        </Routes>
    </div>
}

const MainRoute = ({ body }) => {
    return <>
        <Header />
        <ActionSidebar />
        {body}
    </>
}

const ManagerView = () => {
    const onLoading = useSelector((store) => store.module.onLoading)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const [table, setTable] = useState()

    useEffect(() => {
        const _tbController = new TableController(_pid, "table")
        _tbController.getByListId([_pageId]).then(res => {
            if (res.code === 200) setTable(res.data[0])
            else setTable(undefined)
        })
    }, [_pid, _pageId])

    return (onLoading || !table) ? <div /> : <ModuleListView module={table.Name} />
}

const PageView = () => {
    const onLoading = useSelector((store) => store.page.onLoading)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController(_pid, "page")
        _pageController.getByListId([_pageId]).then(res => {
            if (res.code === 200) PageActions.setSelectedPage(dispatch, res.data[0])
        })
    }, [_pid])

    return <>
        {/* <ActionSidebar /> */}
        {onLoading ? <div /> : <RenderPageByLayers />}
    </>
}

export const PageViewDemo = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const { layers } = useSelector((store) => store.page.data)
    const designTokens = useSelector((store) => store.designToken.data)
    const ref = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        window.addEventListener('message', (event) => {
            switch (event.data.type) {
                case 'GETDATA':
                    PageActions.replaceLayers(dispatch, event.data.data)
                    break;
                case 'SETLAYOUT':
                    PageActions.setLayout(dispatch, event.data.data)
                    break;
                case 'UPDATE':
                    PageActions.updateLayersByMessage(dispatch, event.data.data)
                    break;
                case 'EDIT':
                    PageActions.editLayersByMessage(dispatch, event.data.data)
                    break;
                case 'DELETE':
                    PageActions.deleteLayersByMessage(dispatch, event.data.data)
                    break;
                default:
                    break;
            }
            return true
        });
    }, [])

    useEffect(() => {
        DesignTokenActions.getData(dispatch)
    }, [_pid])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `--${e.Id}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `--${e.Id}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    return <div ref={ref} className={`col ${styles['demo-page-container']}`}>{layers?.length && <RenderPageByLayers onDemo={true} />}</div>
}

export const RenderPageView = () => {
    const location = useLocation()
    const onLoading = useSelector((store) => store.page.onLoading)
    const designTokens = useSelector((store) => store.designToken.data)
    const searchParams = new URLSearchParams(location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        PageActions.getPageDataById(dispatch, _pageId)
        DesignTokenActions.getData(dispatch)
    }, [_pid])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `--${e.Id}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `--${e.Id}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    return <>
        {onLoading ? <div /> : <RenderPageByLayers />}
    </>
}