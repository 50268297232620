import styles from './screen.module.css'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { Button, closePopup, Popup, showPopup, Switch, Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ComponentType } from '../../home/table/da'
import { OutlineButton, OutlineContainer, OutlineInputMultiLine, OutlineInputPipe, OutlineWorkBadge } from '../../../assets/icon'
import { PageActions } from './reducer'
import { onSelectedLayer, onHoverLayer, renderIframeDemo } from './pageView'
import AssetsTab from './assets'
import { TableController } from '../../home/table/controller'
import { randomGID } from '../../../Utils'
import CaptureElement from '../../../project-component/captureElement'
import * as ListLayout from '../../../assets/layout/json'
import RenderPageByLayers from './renderPageView'

export default function LeftView({ pid, listPage = [], onUpdatePage }) {
    const _pageController = new TableController(pid, "page")
    const { page, layers, layout } = useSelector((store) => store.page.data)
    const [tab, setTab] = useState(0)
    const [editId, setEditId] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const popupRef = useRef()
    const [openLayout, setOpenLayout] = useState(false)

    const showPopupSelectLayout = () => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { width: "72%", height: "100%", backgroundColor: "var(--neutral-absolute-background-color)" },
            content: <PopupSelectLayout
                ref={popupRef}
                required={layers && layers.length === 0}
                pid={pid}
                layoutId={listPage.map(e => e.LayoutId).filter(e => e?.length)}
            />
        })
    }

    const handleResizeH = (ev) => {
        ev.preventDefault()
        const pageListElement = ev.target.closest('.page-list-conatiner')
        function onResize(event) {
            const pageListRect = pageListElement.getBoundingClientRect()
            pageListElement.style.height = `${pageListRect.height - pageListRect.bottom + event.pageY}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const handleResizeW = (ev) => {
        ev.preventDefault()
        const _sidebar = ev.target.closest('.setting-page-sidebar.left')
        onSelectedLayer(undefined)
        PageActions.setSelectedLayerId(dispatch, undefined)
        function onResize(event) {
            const pageListRect = _sidebar.getBoundingClientRect()
            _sidebar.style.width = `${pageListRect.width - pageListRect.right + event.pageX}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <>
                    <div className='col page-list-conatiner'>
                        <div className='row' style={{ padding: '0.8rem 0.8rem 0.8rem 1.2rem' }}>
                            <Text className='semibold1' style={{ flex: 1 }}>Pages</Text>
                            <button type='button' className='row icon-button20' onClick={addPage}>
                                <Winicon src={"fill/user interface/e-add"} size={"1.2rem"} />
                            </button>
                        </div>
                        <div className='col' style={{ padding: '0.4rem' }}>
                            {listPage.map((item) => {
                                return <div key={item.Id} className={`row ${styles['page-tile']} ${page?.Id === item.Id ? styles["selected"] : ""}`}
                                    onClick={() => {
                                        const params = new URLSearchParams();
                                        params.append("pid", pid)
                                        params.append("pageid", item.Id)
                                        navigate('?' + params.toString())
                                    }}
                                    onContextMenu={(ev) => { handleRightClick(ev, item) }}
                                >
                                    {editId === item.Id ?
                                        <TextField
                                            autoFocus
                                            className='semibold1'
                                            style={{ padding: "0.4rem" }}
                                            defaultValue={item.Name}
                                            onComplete={(ev) => { ev.target.blur() }}
                                            onBlur={(ev) => {
                                                const _tmp = { ...item, Name: ev.target.value.trim() }
                                                _pageController.edit([_tmp]).then(res => {
                                                    if (res.code === 200) {
                                                        setEditId(undefined)
                                                        onUpdatePage(listPage.map(e => e.Id === _tmp.Id ? _tmp : e))
                                                    }
                                                })
                                            }}
                                        /> :
                                        <Text className='semibold1' style={{ padding: "0.5rem" }}>{item.Name}</Text>}
                                    <button type='button' className='row icon-button24' onClick={showPopupSelectLayout}
                                        ref={btn => {
                                            if (layers?.length === 0 && btn && page) btn.click()
                                        }}
                                    >
                                        <Winicon src='outline/user interface/setup-preferences' />
                                    </button>
                                </div>
                            })}
                        </div>
                        <div className='row' onMouseDown={handleResizeH} />
                    </div>
                    <div className={`row`} style={{ padding: "1.2rem 0.8rem 1.2rem 1.2rem", backgroundColor: "#14181b", borderBottom: "1px solid #323b45" }}>
                        <Text className='semibold1' style={{ flex: 1 }}>Full layout</Text>
                        <Switch value={openLayout} onChange={(v) => {
                            renderIframeDemo("SETLAYOUT", v ? layout : undefined)
                            setOpenLayout(v)
                        }} />
                    </div>
                    <div className={`col ${styles['layer-list-container']}`}>
                        {
                            layout && openLayout ?
                                (layout ?? []).filter(e => !e.ParentId).map(e => <LayerTile key={e.Id} item={e} />) :
                                (layers ?? []).filter(e => !e.ParentId).map(e => <LayerTile key={e.Id} item={e} />)
                        }
                    </div>
                </>
            case 1:
                return <AssetsTab key={page.Id} />
            default:
                return <div />
        }
    }

    const addPage = async (ev) => {
        const _addBtn = ev.target.localName === "button" ? ev.target : ev.target.closest("button")
        _addBtn.disabled = true
        const _newPage = {
            Id: randomGID(),
            Name: 'new page',
            DateCreated: Date.now(),
            Sort: listPage.length
        }
        const addPageRes = await _pageController.add([_newPage])
        if (addPageRes.code !== 200) return ToastMessage.errors(addPageRes.message)
        _addBtn.disabled = false
        onUpdatePage([...listPage, _newPage])
        const params = new URLSearchParams();
        params.append("pid", pid)
        params.append("pageid", _newPage.Id)
        navigate('?' + params.toString())
    }
    const deletePage = async (id) => {
        const deleteRes = await _pageController.delete([id])
        if (deleteRes.code !== 200) return ToastMessage.errors(deleteRes.message)
        const _layerController = new TableController(pid, "layer")
        let _index = listPage.findIndex(e => e.Id === id)
        const _newListPage = listPage.filter(e => e.Id !== id)
        if (page.Id === id) {
            _index = _index > 0 ? (_index - 1) : 0
            const params = new URLSearchParams();
            params.append("pid", pid)
            params.append("pageid", _newListPage[_index].Id)
            navigate('?' + params.toString())
        }
        onUpdatePage(_newListPage)
        const res = await _layerController.getListSimple({ page: 1, size: 1000, query: `@PageId:{${id}}`, returns: ["Id"] })
        if (res.code === 200) _layerController.delete(res.data.map(e => e.Id))
    }

    const handleRightClick = (ev, item) => {
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        <button type="button" onClick={() => {
                            setEditId(item.Id)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Đổi tên</Text>
                        </button>
                        <button type="button" disabled={listPage.length <= 1} onClick={() => {
                            deletePage(item.Id)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Xóa</Text>
                        </button>
                    </div >
                })
                break;
            default:
                break;
        }
    }

    return <div className="row setting-page-sidebar left" style={{ pointerEvents: layers?.length ? undefined : "none" }}>
        <Popup ref={popupRef} />
        <div className='col' style={{ flex: 1, height: '100%' }}>
            <div className='row action-tab'>
                <button type='button' className={`row ${tab === 0 ? "selected" : ""}`} onClick={() => { setTab(0) }}>
                    <Text className='semibold1'>Screen</Text>
                </button>
                <button type='button' className={`row ${tab === 1 ? "selected" : ""}`} onClick={() => { setTab(1) }}>
                    <Text className='semibold1'>Assets</Text>
                </button>
                <div style={{ flex: 1 }} />
                <button type='button' className={`row icon-button20 ${tab === 2 ? "selected" : ""}`} onClick={() => { setTab(2) }}>
                    <Winicon src={"fill/development/zoom"} size={"1.2rem"} />
                </button>
            </div>
            {renderTabView()}
        </div>
        <div className='col' onMouseDown={handleResizeW} />
    </div>
}

const LayerTile = ({ item, space = 0.4 }) => {
    const ref = useRef()
    const { layers, selectedLayerId, layout } = useSelector((store) => store.page.data)
    const children = item.Setting?.className?.includes("layout-body") ? layers.filter(e => !e.ParentId) : (item.PageId ? layers : layout).filter(e => e.ParentId === item.Id)
    const [isOpen, setIsOpen] = useState(true)
    const dispatch = useDispatch()

    const _icon = () => {
        switch (item.Type) {
            case ComponentType.text:
                return <Winicon src='fill/text/text' size={'1.4rem'} />
            case ComponentType.img:
                return <Winicon src={"fill/development/image"} size={'1.4rem'} />
            case ComponentType.checkbox:
                return <Winicon src={"outline/user interface/checkbox-btn-checked"} size={'1.4rem'} />
            case ComponentType.radio:
                return <Winicon src={"outline/user interface/radio-btn-checked"} size={'1.4rem'} />
            case ComponentType.switch:
                return <Winicon src={"outline/user interface/toggle"} size={'1.4rem'} />
            case ComponentType.textField:
                return <OutlineInputPipe color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.textArea:
                return <OutlineInputMultiLine color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.button:
                return <OutlineButton color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.container:
                return <OutlineContainer color={'var(--neutral-text-title-color)'} size={'1.8rem'} />
            case ComponentType.chart:
                return <Winicon src={"outline/business/chart-bar-33"} size='1.4rem' />
            case ComponentType.form:
                return <Winicon src={"outline/development/window-paragraph"} size={'1.4rem'} />
            case ComponentType.card:
                return <OutlineWorkBadge color={'var(--neutral-text-title-color)'} style={{ fontSize: '1.8rem' }} />
            default:
                return null
        }
    }

    return <div id={item.Id} ref={ref} className='col layer-tile-container' style={{ gap: '0.4rem', order: item.Setting?.style?.order }}>
        <div className={`row ${styles['layer-tile']} ${item.Id === selectedLayerId ? styles["selected"] : ""}`} style={{ paddingLeft: `${space}rem` }} onMouseEnter={() => onHoverLayer(item.Id)} onMouseOut={() => onHoverLayer()} onClick={() => { PageActions.setSelectedLayerId(dispatch, item.Id) }}>
            {children.length ? <button type='button' className='row icon-button20' onClick={() => { setIsOpen(!isOpen) }}><Winicon src={`fill/arrows/triangle-${isOpen ? "down" : "right"}`} size='1.2rem' /></button> : null}
            <div className='row icon-button24'>{_icon()}</div>
            <TextField disabled defaultValue={item.Name} className='semibold1' />
        </div>
        {isOpen && children.map(e => <LayerTile key={e.Id} item={e} space={space + 2.8} />)}
    </div >
}

const PopupSelectLayout = forwardRef(function PopupSelectLayout({ required = false, pid, layoutId = [] }, ref) {
    const [tab, setTab] = useState(0)
    const { page } = useSelector((store) => store.page.data)
    const _layerController = new TableController(pid, "layer")
    const [layouts, setLayouts] = useState([])
    const [selectedLayout, setSelectedLayout] = useState()
    const dispatch = useDispatch()

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <div className='row' style={{ flexWrap: "wrap", padding: "1.6rem 2.4rem", gap: "1.6rem", flex: 1, overflow: "hidden auto", alignItems: "start", alignContent: "start" }}>
                    {layouts.filter(e => !e.ParentId).map(e => {
                        const _children = layouts.filter(el => el.LayoutId === e.Id)
                        return <div key={e.Id} className={`col col8 ${styles['layout-model']} ${selectedLayout === e.Id ? styles["selected"] : ""}`} onClick={() => { setSelectedLayout(e.Id) }}>
                            <Text className='label-3'>{e.Name}</Text>
                            <CaptureElement className='col'>
                                <RenderPageByLayers onDemo listLayout={[e, ..._children]} listLayers={[]} />
                            </CaptureElement>
                        </div>
                    })}
                    {Object.keys(ListLayout).map((v) => {
                        const _layers = ListLayout[v]
                        return <div key={v} className={`col col8 ${styles['layout-model']} ${selectedLayout === v ? styles["selected"] : ""}`} onClick={() => { setSelectedLayout(v) }}>
                            <Text className='label-3'>{v}</Text>
                            <CaptureElement className='col'>
                                <RenderPageByLayers onDemo listLayout={_layers} listLayers={[]} />
                            </CaptureElement>
                        </div>
                    })}
                </div>
            case 1:
                return <div></div>
            default:
                return <div />
        }
    }

    const applyLayout = async (ev) => {
        ev.target.disabled = true
        let _layoutId = undefined
        if (selectedLayout.length === 32) {
            _layoutId = selectedLayout
        } else {
            let _layers = ListLayout[selectedLayout].map(e => {
                return { ...e, id: randomGID(), ProjectId: pid, DateCreated: Date.now(), Setting: typeof e.Setting === "string" ? e.Setting : JSON.stringify(e.Setting) }
            })
            _layoutId = _layers.find(e => !e.ParentId)?.id
            _layers = _layers.map(e => {
                let _tmp = { ...e }
                if (e.ParentId) {
                    _tmp.ParentId = _layers.find(el => el.Id === e.ParentId)?.id
                    _tmp.LayoutId = _layoutId
                }
                _tmp.Id = _tmp.id
                delete _tmp.id
                return _tmp
            })
            const res = await _layerController.add(_layers)
            if (res.code !== 200) {
                ev.target.disabled = false
                return ToastMessage.errors(res.message)
            }
        }
        if (_layoutId) {
            const _pageController = new TableController(pid, "page")
            const _pageRes = await _pageController.edit([{ ...page, LayoutId: _layoutId }])
            if (_pageRes.code !== 200) {
                ev.target.disabled = false
                return ToastMessage.errors(_pageRes.message)
            }
            closePopup(ref)
            await PageActions.addDefaultLayers(dispatch, page.Id)
            PageActions.getPageDataById(dispatch, page.Id)
        }
    }

    useEffect(() => {
        if (page && layoutId.length) {
            const _layoutId = []
            for (const id of layoutId) {
                if (_layoutId.every(e => e !== id)) _layoutId.push(id)
            }
            _layerController.getListSimple({ page: 1, size: 1000, query: `(@Id:{${_layoutId.join(" | ")}}) | (@LayoutId:{${_layoutId.join(" | ")}})` }).then(res => {
                if (res.code === 200) setLayouts(res.data.map(e => { return { ...e, Setting: typeof e.Setting === "string" ? JSON.parse(e.Setting) : e.Setting } }))
            })
            if (page.LayoutId) setSelectedLayout(page.LayoutId)
        }
    }, [pid, page])

    return <div className="popup-select-layout col" style={{ flex: 1 }}>
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <button type='button' className={`row semibold2 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Layout</button>
            <button type='button' className={`row semibold2 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Template</button>
            <div style={{ flex: 1 }} />
            {required ? null : <button type='button' className='row icon-button40' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} />
            </button>}
        </div>
        {renderTabView()}
        <div className='row popup-footer'>
            {required ? null : <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { closePopup(ref) }}
            />}
            <Button
                label="Save"
                disabled={!selectedLayout}
                className={selectedLayout ? 'button-primary' : ""}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={applyLayout}
            />
        </div>
    </div>
})