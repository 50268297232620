import styles from './screen.module.css'
import { useDispatch, useSelector } from "react-redux"
import { ComponentType } from "../../home/table/da"
import { useEffect, useRef, useState } from "react"
import { PageActions } from "./reducer"
import { useLocation } from 'react-router-dom'

class LayerAction {
    static onSelected = () => undefined
    static onHover = () => undefined
}

export default function PageView() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _pid = searchParams.get("pid")
    const onLoading = useSelector((store) => store.page.onLoading)
    const { page, layers, layout, selectedLayerId } = useSelector((store) => store.page.data)
    const dispatch = useDispatch()
    const settingPageRef = useRef()
    const iframeRef = useRef()
    const [hoverId, setHoverId] = useState()

    useEffect(() => {
        if (layers?.length || layout?.length)
            LayerAction.onSelected = (_layerId = '') => {
                let _layerItem;
                const _iframe = iframeRef.current
                if (_layerId?.length === 32) _layerItem = _iframe.contentWindow.document.getElementById(_layerId)
                if (_layerItem) {
                    _iframe.contentWindow.document.body.setAttribute("selected-layerid", _layerItem.id)
                    function setSelectedRect() {
                        if (settingPageRef?.current) {
                            const _iframeRect = _iframe.getBoundingClientRect()
                            const _pageRect = settingPageRef.current.getBoundingClientRect()
                            const _layerRect = _layerItem.getBoundingClientRect()
                            settingPageRef.current.style.setProperty('--selectedX', `${_layerRect.x + _iframeRect.x - _pageRect.x}px`)
                            settingPageRef.current.style.setProperty('--selectedY', `${_layerRect.y + _iframeRect.y - _pageRect.y}px`)
                            settingPageRef.current.style.setProperty('--selectedW', `${_layerRect.width}px`)
                            settingPageRef.current.style.setProperty('--selectedH', `${_layerRect.height}px`)
                        } else window.onresize = undefined
                    }
                    setSelectedRect()
                    window.onresize = () => {
                        setSelectedRect()
                        LayerAction.onHover()
                    }
                } else {
                    settingPageRef.current.style.removeProperty('--selectedX')
                    settingPageRef.current.style.removeProperty('--selectedY')
                    settingPageRef.current.style.removeProperty('--selectedW')
                    settingPageRef.current.style.removeProperty('--selectedH')
                    window.onresize = undefined
                }
            }
        return () => { window.onresize = undefined }
    }, [layers?.length, layout?.length])

    useEffect(() => {
        LayerAction.onSelected(selectedLayerId)
        LayerAction.onHover = (_layerId = '') => {
            if (_layerId === selectedLayerId) return setHoverId(undefined)
            let _layerItem;
            const _iframe = iframeRef.current
            if (_layerId) _layerItem = _iframe.contentWindow.document.getElementById(_layerId)
            if (_layerItem) {
                setHoverId(_layerId)
                const _iframeRect = _iframe.getBoundingClientRect()
                const _pageRect = settingPageRef.current.firstChild.getBoundingClientRect()
                const _layerRect = _layerItem.getBoundingClientRect()
                settingPageRef.current.firstChild.style.setProperty('--hoverX', `calc(${_layerRect.x + _iframeRect.x - _pageRect.x}px - 1.6rem)`)
                settingPageRef.current.firstChild.style.setProperty('--hoverY', `calc(${_layerRect.y + _iframeRect.y - _pageRect.y}px - 1.6rem)`)
                settingPageRef.current.firstChild.style.setProperty('--hoverW', `${_layerRect.width}px`)
                settingPageRef.current.firstChild.style.setProperty('--hoverH', `${_layerRect.height}px`)
            } else {
                setHoverId(undefined)
                if (settingPageRef.current?.firstChild) {
                    settingPageRef.current.firstChild.style.removeProperty('--hoverX')
                    settingPageRef.current.firstChild.style.removeProperty('--hoverY')
                    settingPageRef.current.firstChild.style.removeProperty('--hoverW')
                    settingPageRef.current.firstChild.style.removeProperty('--hoverH')
                }
            }
        }
    }, [selectedLayerId])

    const handleDragToTarget = (ev, mousemove = false) => {
        if (!mousemove) ev.preventDefault()
        const _parent = ev.target.getAttribute("component-type") === "Container" && !ev.target.classList.contains("layout-body") && !ev.target.classList.contains("main-layout") ? ev.target : ev.target.closest('div[component-type="Container"]:not(.layout-body, .main-layout)')
        let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent?.id?.length === 32) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = iframeRef.current.contentWindow.document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const handleIframeOnClick = (ev) => {
        ev.preventDefault()
        const _layerItem = ev.target.classList.contains("layer-item") ? ev.target : ev.target.closest('.layer-item')
        if (_layerItem?.id) PageActions.setSelectedLayerId(dispatch, _layerItem?.id)
    }

    const handleIframeOnMouseMove = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        if (ev.buttons === 1) {
            let _layerItem = iframeRef.current.contentWindow.document.body.querySelector(".dragging.layer-item:not(.layout-body, .main-layout)")
            if (_layerItem) {
                handleDragToTarget(ev, true)
            } else {
                const _layerItem = ev.target.classList.contains("layer-item") ? ev.target : ev.target.closest('.layer-item')
                if (_layerItem.classList.contains("page-container")) return false
                const _isSelected = _layerItem && iframeRef.current.contentWindow.document.body.getAttribute("selected-layerid") === _layerItem.id
                if (_isSelected) {
                    const _layerElement = iframeRef.current.contentWindow.document.querySelectorAll(`[id="${_layerItem.id}"]`)
                    _layerElement.forEach((e, i) => {
                        e.classList.add('dragging')
                        e.style.display = 'none'
                    })
                    iframeRef.current.classList.add("dragging")
                } else handleIframeOnClick(ev)
            }
        } else {
            const _layerItem = ev.target.classList.contains("layer-item") ? ev.target : ev.target.closest('.layer-item')
            if (_layerItem) LayerAction.onHover(_layerItem.id)
        }
    }

    const handleOnKeyDown = (ev) => {
        if (ev.target.localName !== 'input' || ev.target.type === 'checkbox') {
            const _iframe = document.getElementById('iframe-render-page-view')
            const _selectedId = _iframe.getAttribute('selected-id')
            const _element = _iframe.contentWindow.document.getElementById(_selectedId)
            let _deleteIds = []
            switch (ev.key.toLowerCase()) {
                case 'delete':
                    if (!_element.classList.contains('page-container') && !_element.classList.contains('main-layout') && !_element.classList.contains('layout-body') && !_element.closest(".comp-text")?.contentEditable) {
                        if (_element) {
                            _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                            _deleteIds.push(_selectedId)
                        }
                        if (_deleteIds.length) PageActions.deleteLayers(dispatch, _deleteIds)
                    }
                    break;
                case 'backspace':
                    if (!_element.classList.contains('page-container') && !_element.classList.contains('main-layout') && !_element.classList.contains('layout-body') && !_element.closest(".comp-text")?.contentEditable) {
                        if (_element) {
                            _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                            _deleteIds.push(_selectedId)
                        }
                        if (_deleteIds.length) PageActions.deleteLayers(dispatch, _deleteIds)
                    }
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        if (!iframeRef.current) return
        window.onkeydown = handleOnKeyDown
        return () => { window.onkeydown = undefined }
    }, [iframeRef.current])

    useEffect(() => {
        if (!iframeRef.current) return
        const handleIframeOnMouseUp = () => {
            let _layerItem = iframeRef.current.contentWindow.document.body.querySelector(".dragging.layer-item:not(.layout-body, .main-layout)")
            if (_layerItem) {
                let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
                if (_demo) {
                    const _parent = _demo.parentElement
                    const _children = [..._parent.children].filter(e => e.id !== _layerItem.id).sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                    const _layerIds = []
                    _children.forEach(e => {
                        const _id = e.id?.length === 32 ? e.id : _layerItem.id
                        if (_layerIds.every(id => _id !== id)) _layerIds.push(_id)
                    })
                    const _layoutItem = _parent.closest('div[layout-id]')
                    let _layoutId = undefined
                    if (_layoutItem) {
                        _layoutId = _layoutItem.getAttribute('layout-id')
                        if (_layoutId === _layoutItem.id || _layoutItem.classList.contains('layout-body')) _layoutId = undefined
                    }
                    const _layerChildren = (_layoutId ? layout : layers).filter(e => e.ParentId === _parent.id || e.Id === _layerItem.id)
                    const updateChildren = iframeRef.current.contentWindow.document.querySelectorAll(`[id="${_layerItem.id}"]`)
                    updateChildren.forEach(e => {
                        e.classList.remove('dragging')
                        e.style.display = ''
                    })
                    _demo.remove()
                    PageActions.editLayers(dispatch, _layerIds.map((id, i) => {
                        let _tmp = _layerChildren.find(el => el.Id === id)
                        return {
                            ..._tmp,
                            ParentId: _parent.id,
                            LayoutId: _layoutId,
                            PageId: _layoutId ? undefined : page.Id,
                            Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }),
                        }
                    }))
                }
            }
            iframeRef.current.classList.remove("dragging")
        }
        const handleIframeOnDrop = (ev) => {
            ev.preventDefault()
            let _demo = iframeRef.current.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)
            if (_demo) {
                const _parent = _demo.parentElement
                const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                const _layoutItem = _parent.closest('div[layout-id]')
                let _layoutId = undefined
                if (_layoutItem) {
                    _layoutId = _layoutItem.getAttribute('layout-id')
                    if (_layoutId === _layoutItem.id || _layoutItem.classList.contains('layout-body')) _layoutId = undefined
                }
                const _layerChildren = (_layoutId ? layout : layers).filter(e => e.ParentId === _parent.id)
                PageActions.editLayers(dispatch, _children.map((e, i) => {
                    let _tmp;
                    if (e.id) {
                        _tmp = _layerChildren.find(el => el.Id === e.id)
                        if (_tmp) return { ..._tmp, Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }) }
                    }
                    _tmp = {
                        LayoutId: _layoutId,
                        PageId: _layoutId ? undefined : page.Id,
                        DateCreated: Date.now(),
                        ParentId: _parent.id,
                    }
                    const _compType = ev.dataTransfer.getData('component-type')
                    switch (_compType) {
                        case ComponentType.form:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.form,
                                Type: ComponentType.form,
                                Setting: { formId: ev.dataTransfer.getData('component-id'), style: { order: i } }
                            }
                            break;
                        case ComponentType.card:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.card,
                                Type: ComponentType.card,
                                Setting: { cardId: ev.dataTransfer.getData('component-id'), style: { width: ev.dataTransfer.getData('component-width'), order: i } }
                            }
                            break;
                        case ComponentType.chart:
                            _tmp = {
                                ..._tmp,
                                Name: ComponentType.chart,
                                Type: ComponentType.chart,
                                Setting: { cardId: ev.dataTransfer.getData('component-id'), style: { order: i } }
                            }
                            break;
                        default:
                            _tmp = {
                                ..._tmp,
                                Name: _compType,
                                Type: _compType,
                                Setting: { style: { order: i } }
                            }
                            if (_compType === ComponentType.container && window.getComputedStyle(_parent).flexDirection === "row") _tmp.Setting.style.width = '4.8rem'
                            break;
                    }
                    return _tmp
                }))
                _parent.removeChild(_demo)
            }
            iframeRef.current.classList.remove('dragging')
        }
        const handleEditTextValue = (ev) => {
            if (ev.target.closest(".comp-text")) ev.target.closest(".comp-text").contentEditable = true
            ev.target.closest(".comp-text").onblur = (e) => {
                e.target.contentEditable = false
                const selectedLayer = [...layers, ...(layout ?? [])].find(l => l.Id === e.target.id)
                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, value: e.target.outerText } }])
            }
        }
        if (layers?.length || layout?.length) {
            iframeRef.current.contentWindow.addEventListener('drop', handleIframeOnDrop);
            iframeRef.current.contentWindow.addEventListener('mouseup', handleIframeOnMouseUp);
            iframeRef.current.contentWindow.addEventListener('dblclick', handleEditTextValue)
        }
        return () => {
            if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.removeEventListener('mouseup', handleIframeOnMouseUp)
                iframeRef.current.contentWindow.removeEventListener('drop', handleIframeOnDrop)
                iframeRef.current.contentWindow.removeEventListener('dblclick', handleEditTextValue)
            }
        }
    }, [layers, layout, iframeRef?.current])

    return <div ref={settingPageRef} className={`col ${styles['setting-page-body']}`} {...(selectedLayerId ? { 'layer-name': [...(layers ?? []), ...(layout ?? [])].find(e => e.Id === selectedLayerId)?.Name } : {})}>
        <div style={{ position: 'relative', flex: 1, width: '100%', height: '100%', border: '1.6rem solid #14181b', borderRadius: '2.4rem' }} {...(hoverId ? { 'layer-name': [...(layers ?? []), ...(layout ?? [])].find(e => e.Id === hoverId)?.Name } : {})}>
            {!onLoading && page && layers?.length && <iframe title='demo-page' ref={iframeRef} id='iframe-render-page-view' selected-id={selectedLayerId} onLoad={(ev) => {
                try {
                    const iframeDoc = ev.target.contentDocument || ev.target.contentWindow.document;
                    iframeDoc.addEventListener('mousemove', handleIframeOnMouseMove);
                    iframeDoc.addEventListener('click', handleIframeOnClick);
                    iframeDoc.addEventListener('dragover', handleDragToTarget);
                    iframeDoc.addEventListener('keydown', handleOnKeyDown);
                    setTimeout(() => {
                        ev.target.contentWindow.postMessage({ type: 'GETDATA', data: layers }, `${window.location.origin}/demoPage?pid=${_pid}&pageid=${page.Id}`);
                    }, 400)
                } catch (error) {
                    console.error('Could not access iframe content due to cross-origin issues:', error);
                }
            }} src={`${window.location.origin}/demoPage?pid=${_pid}&pageid=${page.Id}`} />}
        </div>
    </div>
}

export function renderIframeDemo(type, layers) {
    const _iframe = document.getElementById("iframe-render-page-view")
    if (_iframe) _iframe.contentWindow.postMessage({ type: type, data: layers }, `${window.location.origin}/demoPage${window.location.search}`);
}

export function onSelectedLayer(layerId) { LayerAction.onSelected(layerId) }
export function onHoverLayer(layerId) { LayerAction.onHover(layerId) }