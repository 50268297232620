import { useEffect, useRef, useState } from "react"
import { DataController, SettingDataController } from "../../module/controller"
import { CellAlignItems, Checkbox, ComponentStatus, Dialog, DialogAlignment, Pagination, showDialog, Table, TbBody, TbCell, TbHeader, TbRow, Text, ToastMessage, Winicon } from "wini-web-components"

import { Ultis } from "../../../Utils"
import SettingModel from "../../module/report/settingModel"

export default function SettingReportTab({ pid, item }) {
    const [modelId, setModelId] = useState()

    return modelId != undefined ? <SettingModel
        id={modelId.length ? modelId : undefined}
        pid={pid}
        item={item}
        onSelect={(id) => { setModelId(id) }}
    /> :
        <ListModel
            pid={pid}
            item={item}
            onSelect={(id) => {
                setModelId(id)
            }}
        />
}

const ListModel = ({ pid, item, onSelect }) => {
    const _modelDataController = new SettingDataController({ pid: pid, setting: "model" })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [selectedIds, setSelectedIds] = useState([])
    const dialogRef = useRef()

    const getData = async (page, size) => {
        const res = await _modelDataController.getListSimple({ page: page ?? pageDetails.page, size: size ?? pageDetails.size })
        if (res.code === 200) setData({ data: res.data, totalCount: res.totalCount })
    }

    useEffect(() => { getData() }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: "1.6rem 2.4rem", gap: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ gap: "0.8rem" }}>
            <Text className="heading-7" style={{ flex: 1 }}>Report model</Text>
            <button type="button" className="row button-primary" style={{ padding: '0.4rem 0.8rem' }} onClick={() => { onSelect('') }}>
                <Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />
                <Text className="button-text-3">Create</Text>
            </button>
        </div>
        <div style={{ flex: 1, width: '100%', height: '100%', overflow: "auto" }}>
            <Table>
                <TbHeader>
                    {
                        [
                            <TbCell key={'h0'} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                    <Checkbox
                                        size={'1.6rem'}
                                        value={selectedIds.length && selectedIds.every(id => data.data.some(e => e.Id === id))}
                                        onChange={(v) => {
                                            if (v) { setSelectedIds(data.data.map(e => e.Id)) }
                                            else setSelectedIds([])
                                        }}
                                    />
                                    <Text className='heading-9'>STT</Text>
                                </div>
                            </TbCell>,
                            <TbCell key={'h1'} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Tên</Text>
                            </TbCell>,
                            <TbCell key={'h2'} style={{ minWidth: '8rem' }} align={CellAlignItems.center}>
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Ngày tạo</Text>
                            </TbCell>,
                            <TbCell key={'h3'} fixed style={{ minWidth: '9.6rem', }} align={CellAlignItems.center} >
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Danh sách cột dữ liệu</Text>
                            </TbCell>,
                            <TbCell key={'h4'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Mô tả</Text>
                            </TbCell>,
                            <TbCell key={'hlast'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Action</Text>
                            </TbCell>
                        ]
                    }
                </TbHeader>
                <TbBody>
                    {
                        data.data.map((item, index) => {
                            return <TbRow key={item.Id}>
                                {
                                    [
                                        <TbCell key={'b0'} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                            <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                                <Checkbox size={'1.6rem'} value={selectedIds.some(id => item.Id === id)} onChange={(v) => {
                                                    if (v) setSelectedIds([...selectedIds, item.Id])
                                                    else setSelectedIds(selectedIds.filter(id => id !== item.Id))
                                                }} />
                                                <Text className='heading-9'>{pageDetails.size * (pageDetails.page - 1) + index + 1}</Text>
                                            </div>
                                        </TbCell>,
                                        <TbCell key={'b1'} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                            <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{item.Name}</Text>
                                        </TbCell>,
                                        <TbCell key={'b2'} style={{ minWidth: '8rem' }} align={CellAlignItems.center}>
                                            <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{Ultis.datetoString(new Date(item.DateCreated))}</Text>
                                        </TbCell>,
                                        <TbCell key={'b3'} fixed style={{ minWidth: '9.6rem', maxWidth: '24rem' }} align={CellAlignItems.center} >
                                            <Text className='heading-9' style={{ textAlign: CellAlignItems.center }} maxLine={1}>{item.Columns?.replaceAll(",", ", ")}</Text>
                                        </TbCell>,
                                        <TbCell key={'b4'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                            <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.Description}</Text>
                                        </TbCell>,
                                        <TbCell key={'blast-' + index} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                            <div className='row' style={{ gap: '0.6rem', justifyContent: 'center' }}>
                                                <button type="button" className='row icon-button24' onClick={() => { onSelect(item.Id) }}>
                                                    <Winicon src={"outline/user interface/edit"} />
                                                </button>
                                                <button type='button' className='row icon-button24' onClick={(ev) => {
                                                    showDialog({
                                                        ref: dialogRef,
                                                        alignment: DialogAlignment.center,
                                                        status: ComponentStatus.WARNING,
                                                        title: 'Bạn chắc chắn muốn xóa mẫu báo cáo ' + item.Name,
                                                        content: `Mọi dữ liệu của mẫu báo cáo ${item.Name} sẽ bị xóa vĩnh viễn`,
                                                        onSubmit: async () => {
                                                            const res = await _modelDataController.action("delete", { ids: [item.Id] })
                                                            if (res.code === 200) {
                                                                ToastMessage.success(`Xóa bảng ${item.Name} thành công`)
                                                                getData()
                                                            } else {
                                                                ToastMessage.errors(res.message)
                                                            }
                                                        }
                                                    })
                                                }}><Winicon src={"fill/user interface/trash-can"} size={"1.6rem"} /></button>
                                            </div>
                                        </TbCell>
                                    ]
                                }
                            </TbRow>
                        })
                    }
                </TbBody>
            </Table >
        </div>
        <div style={{ padding: '1.2rem 2.4rem' }}>
            <Pagination
                /// Size
                currentPage={pageDetails.page}
                /// pageSize
                itemPerPage={pageDetails.size}
                // data.total
                totalItem={data.totalCount ?? 0}
                /// action
                onChangePage={(page, size) => {
                    if (pageDetails.page !== page || pageDetails.size !== size) {
                        setPageDetails({ page: page, size: size });
                        getData(page, size)
                    }
                }}
            />
        </div>
    </div>
}
