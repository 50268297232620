import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'wini-web-components';
import { Home, PageViewDemo, RenderPageView } from './router/router';
import { Provider } from 'react-redux';
import { store } from './store';
import LandingLayout from './screen/landing/view';
import LoginView from './screen/login/view';
import { Ultis } from './Utils';

const checkToken = () => {
  // const token = Ultis.getCookie('accessToken') && localStorage.getItem('listActive');
  const token = Ultis.getCookie('accessToken');
  if (token) return true;
  Ultis.clearCookie()
  return false;
};

function App() {
  return <Provider store={store} stabilityCheck="always">
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingLayout />} />
        <Route path="/login" element={<LoginView onSubmit={(ev) => { console.log(ev) }} />} />
        <Route path={'/demoFullPage'} element={checkToken() ? <RenderPageView /> : <Navigate to={"/login"} replace />} />
        <Route path={'/demoPage'} element={checkToken() ? <PageViewDemo /> : <Navigate to={"/login"} replace />} />
        <Route path="/*" element={checkToken() ? <Home /> : <Navigate to={"/login"} replace />} />
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;