import './button.module.css'
import { onSelectedLayer } from "../pageView"
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import React, { useEffect } from 'react'
import { PageActions } from '../reducer'
import { ColorPicker } from './functions/funtions'
import { Ultis } from '../../../../Utils'

export default function SettingSwitch({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)
            if (_newElement) {
                const _newStyle = window.getComputedStyle(_newElement)
                const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                methods.setValue('background-color-value', _bgColor.substring(0, 7))
                methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                methods.setValue('opacity', parseFloat(_newStyle.opacity))

                const _slider = _newElement.querySelector("input+span")
                const _newSliderStyle = window.getComputedStyle(_slider)

                const _bgDotColor = Ultis.rgbToHex(_newSliderStyle.backgroundColor)
                methods.setValue('dot-color-value', _bgDotColor.substring(0, 7))
                methods.setValue('dot-color-opacity', `${Ultis.hexToPercent(_bgDotColor.substring(7))}`)

                if (selectedLayer.Setting.size.includes("px")) {
                    methods.setValue('size', selectedLayer.Setting.size.replace("px", ""))
                    methods.setValue('size-unit', "px")
                } else {
                    methods.setValue('size', selectedLayer.Setting.size.replace("rem", ""))
                    methods.setValue('size-unit', "rem")
                }
            }
        }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/user interface/menu-8"} /></button>
        </div>

        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>

        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Size</Text>
                    <TextField
                        className="regular1"
                        name="size"
                        register={methods.register('size', {
                            onBlur: (ev) => {
                                PageActions.updateLayerAll(dispatch, layers.map(e => {
                                    if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: ev.target.value.trim() + methods.getValues('size-unit') } }
                                    else return e
                                }))
                            }
                        })}
                        defaultValue={methods.getValues('size')}
                        placeholder="size"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('size-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('size-unit')
                                if (_tmp !== v.id) {
                                    PageActions.updateLayerAll(dispatch, layers.map(e => {
                                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: v.id === 'rem' ? `${parseFloat(methods.getValues('size')) / 10}rem` : `${parseFloat(methods.getValues('size')) * 10}${v.id}` } }
                                        else return e
                                    }))
                                }
                            }}
                        />}
                    />
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Active Color</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.8rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {methods.watch("background-color-value") ? <ColorPicker
                colorName={'background-color-value'}
                colorOpcaityName={'background-color-opacity'}
                register={methods.register}
                colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                opacity={methods.watch('opacity')}
                onChange={({ colorValue, opacity }) => {
                    PageActions.updateLayerAll(dispatch, layers.map(e => {
                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, onBackground: colorValue } }
                        else return e
                    }))
                }}
            /> : null}
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Dot Color</Text>
                {methods.watch("dot-color-value") ? <ColorPicker
                    colorName={'dot-color-value'}
                    colorOpcaityName={'dot-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("dot-color-value") + Ultis.percentToHex(parseFloat(methods.watch('dot-color-opacity')))}
                    onChange={({ colorValue, opacity }) => {
                        PageActions.updateLayerAll(dispatch, layers.map(e => {
                            if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, dotColor: colorValue } }
                            else return e
                        }))
                    }}
                /> : null}
            </div>
        </div>


    </>
}