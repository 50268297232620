import styles from './view.module.css'
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CellAlignItems, Checkbox, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components";
import { TableController } from "./controller";
import PopupAddEditTable from "./keys/createTb";
import { Ultis } from "../../../Utils";
import { OutlineAppKey, OutlineEnlargeVertical, OutlineAppCoins } from "../../../assets/icon";
import PopupSettingAssets from './store';
import { useForm } from 'react-hook-form';
import PopupSearchIn from '../../module/manager/seachIn';

const searchkeyVl = "keysearch";
export default function ListTable() {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _tbController = new TableController(_pid, 'table')
    const ref = useRef()
    const dialogRef = useRef()
    const [tables, setTables] = useState({ data: [] })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "68%", height: "100%" },
            content: <PopupAddEditTable
                ref={ref}
                pid={_pid}
                item={item}
                onSuccess={getTables}
            />
        })
    }

    const showPopupSettingAssets = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%", borderRadius: 0 },
            content: <PopupSettingAssets
                ref={ref}
                pid={_pid}
                item={item}
            />
        })
    }

    const getTables = async (page, size) => {
        let _query = []
        for (const [key, value] of searchParams) {
            if (key !== "pid") {
                if (key == searchkeyVl) {
                    methods.setValue('search-key-value', value)
                }
                if (key == "searchin") {
                    const listKeySearch = value.split(',')
                    if (listKeySearch.length > 0) {
                        listKeySearch.forEach(element => {
                            _query.push(`@${element}:{${searchParams.get(searchkeyVl)}}`)
                        });
                    }
                }
            }
        }

        const res = await _tbController.getListSimple({
            page: page ?? pageDetails.page, size: size ?? pageDetails.size,
            query: _query.length && searchParams.has(searchkeyVl) ? _query.join(" | ") : "*",
        })
        if (res.code === 200) {
            setTables({ data: res.data, count: res.count })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const searchKeyAction = async (e) => {
        if (e.target.value.length == 0) {
            searchParams.delete(searchkeyVl)
        } else {
            if (searchParams.has(searchkeyVl))
                searchParams.set(searchkeyVl, e.target.value.trim());
            else searchParams.append(searchkeyVl, e.target.value.trim());
        }
        navigate('?' + searchParams.toString());

        getTables(pageDetails.page, pageDetails.size)
    }

    const showSearchBox = (event) => {
        var listFilterSearch = [{ Id: "0", Name: "Name", Setting: { Title: "Name", isChecked: false } }, { Id: "1", Name: "Description", Setting: { Title: "Description", isChecked: false } }]

        for (const [key, value] of searchParams) {
            if (key === "searchin") {
                const valueList = value.split(',')
                if (valueList) {
                    listFilterSearch.forEach(element => {
                        if (valueList.find((e) => e == element.Setting.Title)) {
                            element.Setting.isChecked = true
                        }
                    });
                }
            }
        }
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', width: event.target.closest('div').getBoundingClientRect().width, top: event.target.closest('div').getBoundingClientRect().bottom, left: event.target.closest('div').getBoundingClientRect().x, bottom: 0, maxHeight: '100%' },
            content: <PopupSearchIn ref={ref}
                cols={listFilterSearch} />
        })
    }

    useEffect(() => {
        if (_pid) getTables()
    }, [_pid])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-bolder-border-color)' }}>
            <button type='button' className='row' style={{ gap: '1.6rem' }}>
                <Winicon src="outline/files/folder" size={'3.2rem'} />
                <Text className='heading-5'>Data Table</Text>
                <Winicon src={"fill/arrows/down-arrow"} size={"1.6rem"} />
            </button>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <div className='row' style={{ height: '3.2rem', alignItems: "stretch", border: "var(--neutral-bolder-border-color)", borderRadius: '0.8rem' }} >
                    <button type='button' className='row' style={{ padding: '0 1.2rem', borderRight: "var(--neutral-bolder-border-color)", gap: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                        <Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Add table</Text>
                    </button>
                    <div className='row' style={{ padding: '0.8rem' }}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                    suffix={<button type='button' onClick={(event) => showSearchBox(event)}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </button>}
                    name='txtSearch'
                    onComplete={searchKeyAction}
                    defaultValue={`${searchParams.get(searchkeyVl) ?? ''}`}
                    register={methods.register('search-key-value')}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='outline/user interface/setup-preferences' size={'1.6rem'} />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='fill/arrows/enlarge' size={'1.6rem'} />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            <div className={`col ${styles['table']}`}>
                <div className={`row ${styles['header']}`}>
                    <div key={'h0'} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                        <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                        <div className='row' style={{ height: '100%', gap: '1.2rem', paddingLeft: "0.4rem", flex: 1 }}>
                            <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>Name</Text>
                            <button type='button' className={`row icon-button24 ${styles['button-sort']}`} >
                                <OutlineEnlargeVertical color={"#878792"} />
                            </button>
                        </div>
                    </div>
                    <div key={'h2'} className='row' style={{ width: '20rem' }}>
                        <Text className='heading-9' >Created date</Text>
                    </div>
                    <div key={'h3'} className='row' style={{ width: '40rem' }}>
                        <Text className='heading-9'>Description</Text>
                    </div>
                    <div key={'hlast'} className='row' style={{ width: "14rem" }}>
                        <Text className='heading-9' style={{ flex: 1, textAlign: CellAlignItems.center }}>Action</Text>
                    </div>
                </div>
                {
                    tables.data.map((item, index) => <div key={`${item.Id}`} className='row'>
                        <div key={'b0-' + index} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                            <Text className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{item.Name}</Text>
                        </div>
                        <div key={'b2-' + index} className='row' style={{ width: '20rem' }}>
                            <Text className='body-3'>{item.DateCreated ? Ultis.datetoString(new Date(item.DateCreated), "dd/mm/yyyy hh:mm") : "-"}</Text>
                        </div>
                        <div key={'b3-' + index} className='row' style={{ width: '40rem' }}>
                            <Text className='body-3'>{item.Description ?? "-"}</Text>
                        </div>
                        <div key={'blast-' + index} className='row' style={{ gap: "0.8rem", justifyContent: 'center', width: "14rem" }} >
                            <button type='button' className='row icon-button24' onClick={() => { showPopupAddEdit(item) }}>
                                <OutlineAppKey />
                            </button>
                            <button type='button' className='row icon-button24' onClick={() => { showPopupSettingAssets(item) }}>
                                <OutlineAppCoins size={'1.8rem'} />
                            </button>
                            <button type='button' className='row icon-button24' onClick={(ev) => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: 'Are you sure to delete ' + item.Name + ' table?',
                                    content: `You will not be able to recover data or setting of ${item.Name} table!`,
                                    submitTitle: "Delete",
                                    cancelTitle: "Cancel",
                                    onSubmit: async () => {
                                        ev.target.closest("button").disabled = true
                                        const res = await _tbController.delete([item.Id])
                                        if (res.code === 200) {
                                            ToastMessage.success(`Xóa bảng ${item.Name} thành công`)
                                            getTables()
                                        } else {
                                            ToastMessage.errors(res.message)
                                        }
                                        ev.target.closest("button").disabled = false
                                    }
                                })
                            }}><Winicon src='outline/user interface/trash-can' /></button>
                        </div>
                    </div>)
                }
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={tables?.count ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getTables(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}