import styles from './cards.module.css'
import React, { CSSProperties, ReactNode, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { Button, Text, Winicon } from "wini-web-components"
import MapGroupData from "../screen/module/dashboard/mapGroupData"
import { ChartItem, DashBoardCardType } from "../screen/module/da"
import { DataController } from "../screen/module/controller"
import { RenderComponentByType } from "../screen/module/config"
import { FieldValues, UseFormReturn } from "react-hook-form"
import { ColumnItem, ComponentType, RelativeItem } from "../screen/home/table/da"
import { Select1Form, SelectMultipleForm } from "./component-form"

export const CardInformationVertical = (params: {
    thumbnail?: string,
    media?: ReactNode,
    title?: string,
    titleElement?: ReactNode,
    content?: string,
    contentElement?: ReactNode,
    bottomAction?: ReactNode,
    to?: string,
    className?: string,
    style?: CSSProperties,
}) => {
    return <div className={`col ${styles['card-container']} ${params.className ?? ''}`} style={params.style}>
        {params.thumbnail ? params.to ? <NavLink to={params.to} className={`row ${styles['card-thumbnail-container']}`}>
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </NavLink> : <div className={`row ${styles['card-thumbnail-container']}`}>
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </div> : undefined}
        {params.media}
        <div className="col" style={{ gap: '0.4rem', textAlign: 'inherit' }}>
            {params.titleElement ?? <Text className="heading-6" maxLine={2} style={{ textAlign: 'inherit', color: params.style?.color ?? '#00204D' }}>{params.title}</Text>}
            {params.contentElement ?? <Text className="subtitle-2" maxLine={4} style={{ color: params.style?.color ?? '#00204D99', textAlign: 'inherit' }}>{params.content}</Text>}
        </div>
        {params.bottomAction ? params.bottomAction : params.to ? <NavLink to={params.to} className={'row'} style={{ gap: '0.8rem' }}>
            <Text className="button-text-3" style={{ color: 'var(--primary-main-color)' }}>Learn more</Text>
            <Winicon src={"fill/arrows/arrow-right"} size={"1.4rem"} color='var(--primary-main-color)' />
        </NavLink> : undefined}
    </div>
}

export const ChartCard = (params: { id: string, pid: string, item: ChartItem, timeRannge?: number, className?: string, style?: CSSProperties }) => {
    const [_result, setResult] = useState([])
    const [cardItem, setCardItem] = useState<any>()
    const _groupByTimeChart = [DashBoardCardType.bar, DashBoardCardType.horizontalBar, DashBoardCardType.bubble, DashBoardCardType.line, DashBoardCardType.scatter, DashBoardCardType.radar]

    useEffect(() => {
        if (params.item) {
            const _reducers: Array<any> = [{ Name: params.item.Name, GroupBy: params.item.GroupBy, Reducer: params.item.Reducer, ReducerBy: params.item.ReducerBy }]
            if (params.item.Type && _groupByTimeChart.includes(params.item.Type)) {
                if ((params.timeRannge ?? 120) > 30) {
                    _reducers.push({ Name: "_month", GroupBy: "_month", Query: `"timefmt(month(@DateCreated/1000), '%m')"` })
                } else {
                    _reducers.push({ Name: "_day", GroupBy: "_day", Query: `"timefmt(@DateCreated/1000, '%d-%m')"` })
                }
            }
            const _tbRelController = new DataController({ pid: params.pid, module: (params.item as any).TableFK })
            const _now = new Date()
            const _startDate = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate() - (params.timeRannge ?? 120))
            _tbRelController.groupBy({
                searchRaw: `@DateCreated:[${_startDate.getTime()} ${_now.getTime()}]`,
                reducers: _reducers
            }).then((res) => {
                if (res.code === 200) {
                    setResult(res.data)
                    setCardItem({ ...params.item, Setting: JSON.parse(params.item.Setting as any) })
                }
            })
        }
    }, [params.item, (params.timeRannge ?? 120)])

    return <div id={params.id} className={`col ${styles['chart-container']} ${params.className ?? ""}`} style={params.style}>
        <Text className='heading-7' style={{ flex: 1 }}>{cardItem?.Title}</Text>
        {
            cardItem && _result.length && <MapGroupData
                listData={_result}
                cardItem={cardItem}
                filterTime={(params.timeRannge ?? 120) > 30 ? "_month" : "_day"}
            />
        }
    </div>
}

export const FormCard = (params: { id?: string, methods: UseFormReturn<FieldValues, any, undefined>, cols: Array<ColumnItem>, rels: Array<RelativeItem>, name?: string, description?: string, className?: string, style?: CSSProperties, hideHeader?: boolean, labelPosition?: "top" | "left", options?: { [p: string]: Array<any> }, onSubmit?: () => void, onCancel?: () => void }) => {
    return <div id={params.id} className={`col ${styles['form-container']} ${params.className ?? ""}`} style={params.style}>
        {params.hideHeader ? null : <div className={`col ${styles['form-header']}`} style={{ gap: '0.4rem' }}>
            <Text className='heading-7'>{params.name ?? "Lorem Ipsum is simply dummy text"}</Text>
            <Text className='body-3'>{params.description ?? "Description"}</Text>
        </div>}
        <form className={`col ${styles['form-body']}`}>
            {params.cols.filter(e => e.Name !== "Id" && e.Name !== "DateCreated").map((_col: any) => {
                return <RenderComponentByType
                    key={_col.Id}
                    methods={params.methods}
                    fieldItem={_col}
                    label={undefined}
                    style={{ order: _col.Form.Sort }}
                    className={params.labelPosition === "left" ? "row" : undefined}
                    labelStyle={params.labelPosition === "left" ? { width: "16rem" } : undefined}
                />
            })}
            {params.rels.map((_rel: any) => {
                switch (_rel.Form?.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={params.methods.control}
                            errors={params.methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(params.options?.[`${_rel.TablePK}_Options`] ?? []).map((e: any) => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                            style={{ order: _rel.Form.Sort }}
                            className={params.labelPosition === "left" ? "row" : undefined}
                            labelStyle={params.labelPosition === "left" ? { width: "16rem" } : undefined}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={params.methods.control}
                            errors={params.methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(params.options?.[`${_rel.TablePK}_Options`] ?? []).map((e: any) => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                            style={{ order: _rel.Form.Sort }}
                            className={params.labelPosition === "left" ? "row" : undefined}
                            labelStyle={params.labelPosition === "left" ? { width: "16rem" } : undefined}
                        />
                }
            })}
        </form>
        <div className={`row ${styles['form-footer']}`}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={params.onCancel}
            />
            <Button
                label="Save"
                className='button-primary'
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={params.onSubmit}
            />
        </div>
    </div>
}

