import styles from './assest.module.css'
import { closePopup, InfiniteScroll, Popup, showPopup, Text, TextField, Winicon } from "wini-web-components";
import { ComponentType } from "../../home/table/da";
import { OutlineButton, OutlineContainer, OutlineInputMultiLine, OutlineInputPipe } from "../../../assets/icon";
import { forwardRef, useEffect, useRef, useState } from 'react';
import { TableController } from '../../home/table/controller';
import TbAssetsChart from './tbAssestsTab/chart';
import TbAssetsForm from './tbAssestsTab/form';
import TbAssetsCard from './tbAssestsTab/card';

export default function AssetsTab() {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _tbController = new TableController(_pid, "table")
    const popupRef = useRef()
    const [selectedTb, setSelectedTb] = useState()
    const [tables, setTables] = useState({ data: [], totalCount: undefined })
    const tbAssets = ["Card", "Form", "Chart", "Report"]
    const _iframe = document.getElementById("iframe-render-page-view")

    const getTables = async (page) => {
        const res = await _tbController.getListSimple({ page: page ?? 1, size: 20, returns: ["Id", "Name"] })
        if (res.code === 200) {
            if (page) {
                setTables({ data: [...tables.data, ...res.data], totalCount: res.count })
            } else {
                setTables({ data: res.data, totalCount: res.count })
            }
        }
    }

    const renderComponentOption = (prop) => {
        switch (ComponentType[prop]) {
            case ComponentType.text:
                var icon = <Winicon src='fill/text/text' />
                break;
            case ComponentType.img:
                icon = <Winicon src={"fill/development/image"} />
                break;
            case ComponentType.checkbox:
                icon = <Winicon src={"outline/user interface/checkbox-btn-checked"} />
                break;
            case ComponentType.switch:
                icon = <Winicon src={"outline/user interface/toggle"} />
                break;
            case ComponentType.textField:
                icon = <OutlineInputPipe size={'2.8rem'} />
                break;
            case ComponentType.button:
                icon = <OutlineButton size={'2.8rem'} />
                break;
            case ComponentType.container:
                icon = <OutlineContainer size={'2.8rem'} />
                break;
            case ComponentType.radio:
                icon = <Winicon src={"outline/user interface/radio-btn-checked"} />
                break;
            case ComponentType.calendar:
                icon = <Winicon src={"outline/user interface/calendar-date"} />
                break;
            case ComponentType.textArea:
                icon = <OutlineInputMultiLine size={'3.2rem'} />
                break;
            case ComponentType.navLink:
                icon = <Winicon src={"fill/user interface/link"} />
                break;
            case ComponentType.upload:
                icon = <Winicon src={"outline/arrows/cloud-upload"} />
                break;
            case ComponentType.table:
                icon = <Winicon src={"outline/user interface/gallery-view"} />
                break;
            default:
                return null
        }
        return <div key={prop} draggable className={`col col8 ${styles['component-options']}`} style={{ alignItems: 'center' }}
            onDragStart={(ev) => {
                ev.dataTransfer.setData("component-type", ComponentType[prop])
                _iframe.classList.add('dragging')
            }}
            onDragEnd={() => {
                _iframe.classList.remove('dragging')
                _iframe.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)?.remove()
            }}
        >
            {icon}
            <Text className="regular1" maxLine={1}>{ComponentType[prop]}</Text>
        </div>
    }

    const showPopupCardOptions = () => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { width: '68%', height: '80%', backgroundColor: "#14181b" },
            content: <PopupCardOptions ref={popupRef} />
        })
    }

    const showSelectedTbView = () => {
        switch (selectedTb) {
            case "Basic":
                return <>
                    <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(undefined) }}>
                        <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                        <Text className="semibold1" style={{ flex: 1 }}>Basic Components</Text>
                    </button>
                    <div className={`row ${styles['component-options-group']}`}>
                        {Object.keys(ComponentType).map((prop) => renderComponentOption(prop))}
                    </div>
                </>
            default:
                const _tb = tables.data.find(e => selectedTb.includes(e.Id))
                const _type = tbAssets.find(e => selectedTb.endsWith(e))
                switch (_type) {
                    case 'Card':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Card</Text>
                            </button>
                            <TbAssetsCard tbName={_tb.Name} />
                        </>
                    case 'Form':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Form</Text>
                            </button>
                            <TbAssetsForm tbName={_tb.Name} />
                        </>
                    case 'Chart':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Chart</Text>
                            </button>
                            <TbAssetsChart tbName={_tb.Name} />
                        </>
                    case 'Report':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Report</Text>
                            </button>
                        </>
                    default:
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(undefined) }}>
                                <Winicon src='fill/arrows/left-arrow' size={'1.2rem'} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name}</Text>
                            </button>
                            {tbAssets.map((e) => {
                                return <button key={e} type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb(_tb.Id + "_" + e) }}>
                                    <Text className="semibold1" style={{ flex: 1, paddingLeft: '1.2rem' }}>{e}</Text>
                                    <Winicon src='fill/arrows/right-arrow' size={'1.2rem'} />
                                </button>
                            })}
                        </>
                }
        }
    }

    useEffect(() => { getTables() }, [])

    const handleScroll = (endScroll = false) => {
        if (endScroll) getTables((tables.data.length / 20) + 1)
    }

    return <>
        <Popup ref={popupRef} />
        <TextField
            placeholder="Search for components..."
            className="regular1"
            style={{ padding: '1rem 0.6rem 1rem 1.2rem', borderRadius: 0 }}
            prefix={<Winicon src={"fill/development/zoom"} size={"1.2rem"} />}
            suffix={<button type="button" className="row icon-button24" onClick={showPopupCardOptions}>
                <Winicon src={"fill/user interface/setup-preferences"} size={"1.2rem"} />
            </button>}
        />
        <InfiniteScroll handleScroll={tables.totalCount && tables.totalCount > tables.data.length ? handleScroll : undefined} className={`col ${styles['assets-options-container']}`}>
            {selectedTb ? showSelectedTbView() : <>
                <button type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb("Basic") }}>
                    <Text className="semibold1" style={{ flex: 1 }}>Basic Components</Text>
                    <Winicon src='fill/arrows/right-arrow' size={'1.2rem'} />
                </button>
                {tables.data.map((_tb, i) => {
                    return <button key={_tb.Id} type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb(_tb.Id) }}>
                        <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name}</Text>
                        <Winicon src='fill/arrows/right-arrow' size={'1.2rem'} />
                    </button>
                })}
            </>}
        </InfiniteScroll>
    </>
}

const PopupCardOptions = forwardRef(function PopupCardOptions(data, ref) {
    const [tab, setTab] = useState(0)

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <div />
            case 1:
                return <div></div>
            default:
                return <div />
        }
    }

    return <div className={`col ${styles['popup-card-options']}`} style={{ flex: 1 }}>
        <div className={`row ${styles['popup-header']}`}>
            <button type='button' className={`row semibold2 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Card</button>
            <button type='button' className={`row semibold2 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Template</button>
            <div style={{ flex: 1 }} />
            <button type='button' className='row icon-button40' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} />
            </button>
        </div>
        {renderTabView()}
    </div>
})