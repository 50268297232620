import styles from './container.module.css'
import { Controller, useForm } from "react-hook-form"
import { Text, Select1, TextField, Winicon } from "wini-web-components"
import { OutlineArrowDown, OutlineArrowRight, OutlineArrowTurnLeft, OutlineRadius4Angle, OutlineRadiusAll } from "../../../../assets/icon"
import { useEffect, useState } from "react"
import { PageActions } from "../reducer"
import { useDispatch, useSelector } from "react-redux"
import { onSelectedLayer } from "../pageView"
import { Ultis } from '../../../../Utils'
import { ColorPicker } from './functions/funtions'
import { CheckboxForm } from '../../../../project-component/component-form'
import { DesignTokenType } from '../../../home/designToken/da'
import { OnChangeBorderStyle, OnChangePaddingStyle, OnChangePosition, OnChangeWH } from './functions/common'

export default function SettingContainer({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const [breakPoint, setBreakPoint] = useState(false)
    const _iframe = document.getElementById("iframe-render-page-view")
    const designToken = useSelector((store) => store.designToken.data)
    const [hasDesignToken, setHasDesignToken] = useState(false)

    const updateStyle = (style, className) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        if (className) {
            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle, className: className } }])
        } else {
            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
        }
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer.Id)
            if (_newElement) {
                const _newStyle = window.getComputedStyle(_newElement)
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                const _direction = _newStyle.flexDirection
                methods.setValue('flex-direction', _direction)

                methods.setValue('flex-wrap', _newStyle.flexWrap)
                methods.setValue('flex-alignment', (_direction === "column" ? (_newStyle.justifyContent + "-" + _newStyle.alignItems) : (_newStyle.alignItems + "-" + _newStyle.justifyContent)).replaceAll("normal", "start"))
                if (selectedLayer.Setting.style?.backgroundColor?.startsWith("var")) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', selectedLayer.Setting.style?.backgroundColor.replace("var(--", "").replace(")", ""))
                    setHasDesignToken(true)
                } else if (selectedLayer.Setting.style?.backgroundColor) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', undefined)
                } else {
                    methods.setValue('background-color-id', undefined)
                    methods.setValue('background-color-value', undefined)
                }

                const _isScrollX = _newStyle.overflowX // hidden
                const _isScrollY = _newStyle.overflowY // hidden

                methods.setValue('isScroll', _isScrollY != undefined && _isScrollY != 'hidden' || _isScrollX != undefined && _isScrollX != 'hidden')

                if (_newStyle.flexWrap === "wrap") {
                    const _gapC = _newStyle['column-gap']
                    if (_gapC === "normal" || selectedLayer.Setting.style?.columnGap?.includes("px")) {
                        methods.setValue('column-gap', _gapC === "normal" ? 0 : _gapC.replace("px", ""))
                        methods.setValue('column-gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.columnGap?.includes("%")) {
                        methods.setValue('column-gap', parseFloat(_gapC.replace("%", "")))
                        methods.setValue('column-gap-unit', "%")
                    } else {
                        methods.setValue('column-gap', (parseFloat(_gapC.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('column-gap-unit', "rem")
                    }
                    const _gapR = _newStyle['row-gap']
                    if (_gapR === "normal" || selectedLayer.Setting.style?.rowGap?.includes("px")) {
                        methods.setValue('row-gap', _gapR === "normal" ? 0 : _gapR.replace("px", ""))
                        methods.setValue('row-gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.rowGap?.includes("%")) {
                        methods.setValue('row-gap', parseFloat(_gapR.replace("%", "")))
                        methods.setValue('row-gap-unit', "%")
                    } else {
                        methods.setValue('row-gap', (parseFloat(_gapR.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('row-gap-unit', "rem")
                    }
                } else {
                    const _gap = _newStyle['gap'].split(" ")[0]
                    if (_gap === "normal" || selectedLayer.Setting.style?.gap?.includes("px")) {
                        methods.setValue('gap', _gap === "normal" ? 0 : _gap.replace("px", ""))
                        methods.setValue('gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.gap?.includes("%")) {
                        methods.setValue('gap', parseFloat(_gap.replace("%", "")))
                        methods.setValue('gap-unit', "%")
                    } else {
                        methods.setValue('gap', (parseFloat(_gap.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('gap-unit', "rem")
                    }
                }

            }
            onSelectedLayer(selectedLayer.Id)
        }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/user interface/menu-8"} /></button>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Layout</Text>
            <button type="button" className="row icon-button16"><Winicon size={'1.6rem'} src='outline/arrows/down-arrow' color='var(--neutral-text-subtitle-color)' /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.8rem', flex: 1, height: '100%' }}>
                    <div className={`row ${styles['flex-direction-options']}`}>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'column' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = { ...selectedLayer.Setting.style, overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined }
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'row'), "col"].join(" ")
                                    }
                                }])
                            }}
                        >
                            <OutlineArrowDown color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'row' && methods.watch("flex-wrap") !== 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = {
                                    ...selectedLayer.Setting.style,
                                    overflowX: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowY: undefined
                                }
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowRight color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-wrap") === 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: {
                                            ...selectedLayer.Setting.style, flexWrap: 'wrap',
                                            overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined
                                        }, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowTurnLeft color={'#fff'} size={'1.4rem'} />
                        </button>
                    </div>
                    {methods.watch("flex-wrap") !== 'wrap' ? <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        name="gap"
                        register={methods.register('gap', {
                            onBlur: (ev) => {
                                updateStyle({
                                    gap: ev.target.value.trim() + methods.getValues('gap-unit'),
                                    rowGap: undefined,
                                    columnGap: undefined,
                                })
                            }
                        })}
                        placeholder="gap"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('gap-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('gap-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        gap: v.id === 'rem' ? `${parseFloat(methods.getValues('gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('gap'))}${v.id}`,
                                        rowGap: undefined,
                                        columnGap: undefined,
                                    })
                                }
                            }}
                        />}
                    /> : null}
                </div>
                <div className={`row ${styles['alignment-options']}`}>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = { justifyContent: 'start', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start center'
                            updateStyle(_v)
                        }}
                    >
                        <div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'end' } : { justifyContent: 'end', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'start' } : { justifyContent: 'start', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = { justifyContent: 'center', alignItems: methods.getValues("flex-direction") === 'column' ? 'center' : undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'end' } : { justifyContent: 'end', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'start' } : { justifyContent: 'start', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = { justifyContent: 'end', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                </div>
                <button type="button" className="row icon-button24">
                    <Winicon src={"fill/user interface/gear"} size='1.2rem' />
                </button>
            </div>
            {methods.watch("flex-wrap") === "wrap" ? <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}

                    className="regular1"
                    name="column-gap"
                    register={methods.register('column-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                columnGap: ev.target.value.trim() + methods.getValues('column-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="column-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('column-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('column-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    columnGap: v.id === 'rem' ? `${parseFloat(methods.getValues('column-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('column-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('column-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    name="row-gap"
                    register={methods.register('row-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                rowGap: ev.target.value.trim() + methods.getValues('row-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="row-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('row-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('row-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    rowGap: v.id === 'rem' ? `${parseFloat(methods.getValues('row-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('row-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('row-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <div style={{ width: '1.6rem' }} />
            </div> : null}
            <div className="col" style={{ gap: '0.4rem', }}>
                <CheckboxForm label={<Text className="semibold1" >IsScroll</Text>} name="isScroll" size={16} control={methods.control} onChange={(v) => {
                    const flexDirection = methods.getValues('flex-direction')
                    const flexWrap = methods.getValues('flex-wrap')

                    if (v) {
                        updateStyle({ overflow: undefined, overflowY: flexDirection === 'column' || flexWrap === 'wrap' ? 'scroll' : undefined, overflowX: flexDirection === 'row' && flexWrap !== 'wrap' ? 'scroll' : undefined })
                    } else {
                        updateStyle({ overflowY: undefined, overflowX: undefined, overflow: undefined })
                    }
                }} />
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setBreakPoint(!breakPoint) }}><Winicon src={"fill/development/layout-25"} /></button>
                <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
            </div>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {selectedLayer.ParentId &&
                <div className="col" style={{ gap: '0.8rem', }}>
                    <OnChangeWH methods={methods} breakPoint={breakPoint} setBreakPoint={setBreakPoint} onChange={updateStyle} selectedLayer={selectedLayer} selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer.Id)} />
                </div>
            }
            <OnChangePosition methods={methods} setBreakPoint={setBreakPoint} onChange={updateStyle} selectedLayer={selectedLayer} selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer.Id)} />
            <OnChangePaddingStyle methods={methods} onChange={updateStyle} selectedLayer={selectedLayer} selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer.Id)} />
            <OnChangeBorderStyle methods={methods} onChange={updateStyle} selectedLayer={selectedLayer} selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer.Id)} hasDesignToken={hasDesignToken} setDesignToken={setHasDesignToken} designToken={designToken} />
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setHasDesignToken(!hasDesignToken) }}><Winicon src={"fill/business/coins"} /></button>
                {methods.watch("background-color-value") ? <button type="button" className="row icon-button16">
                    <Winicon src={"fill/arrows/down-arrow"} />
                </button> : <button type="button" className="row icon-button16" onClick={() => { updateStyle({ backgroundColor: '#ffffffff' }) }}>
                    <Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />
                </button>}
            </div>

        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {hasDesignToken && designToken?.length > 0 ?
                <Select1
                    className="regular1"
                    placeholder={`Design Color`}
                    value={methods.watch('background-color-id')}
                    options={designToken.filter(el => el.Type === DesignTokenType.group).map((e) => [e, ...designToken.filter(el => el.ParentId === e.Id)]).reduce((a, b) => a.concat(b)).map((el) => {
                        return {
                            id: el.Id,
                            name: el.Type === DesignTokenType.group ? <Text className='label-3'>{el.Name}</Text> : <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                }}></div> | <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                }}></div>
                                <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                            </div>,
                            disabled: el.Type === DesignTokenType.group
                        }
                    })}
                    style={{}}
                    onChange={(v) => {
                        const _tmp = methods.getValues('background-color-id')
                        if (_tmp !== v.id) {
                            updateStyle({ backgroundColor: `var(--${v.id})` })
                        }
                    }}
                /> :
                methods.watch("background-color-value") ? <ColorPicker
                    colorName={'background-color-value'}
                    colorOpcaityName={'background-color-opacity'}
                    style={{ width: '100%', height: 28, }}
                    register={methods.register}
                    colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                    opacity={methods.watch('opacity')}
                    onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity })}
                    onDelete={() => updateStyle({ backgroundColor: undefined, opacity: undefined })}
                /> : null}
        </div>
    </>
}

