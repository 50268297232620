import { useForm } from "react-hook-form";
import { Text, ToastMessage, Winicon } from "wini-web-components";
import { CKEditorForm, ImportFileForm, TextFieldForm } from "../../../project-component/component-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ProjectController } from "./controller";
import { randomGID } from "../../../Utils";
import { BaseDA } from "../../../da/baseDA";
import { useEffect } from "react";

export default function CreateProject() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const _pid = params.get("id")
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const onSubmit = async (ev) => {
        if (_pid) {
            if (typeof ev.LogoId !== "string") {
                const uploadRes = await BaseDA.uploadFiles([ev.LogoId])
                if (uploadRes) ev.LogoId = uploadRes[0].id
            }
            const res = await ProjectController.edit([ev])
            if (res.code === 200) navigate('/home', { replace: true })
            else ToastMessage.errors(res.message)
        } else {
            const newProject = {
                ...ev,
                Id: randomGID(),
                CustomerId: "b081a926bb0c4318aebce949de0ce998",
                DateCreated: Date.now(),
            }
            const uploadRes = await BaseDA.uploadFiles([ev.LogoId])
            if (uploadRes) newProject.LogoId = uploadRes[0].id
            const res = await ProjectController.add([newProject])
            if (res.code === 200) navigate('/home', { replace: true })
            else ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (_pid) {
            ProjectController.getByIds([_pid]).then(res => {
                if (res.code === 200) {
                    Object.keys(res.data[0]).forEach(k => {
                        methods.setValue(k, res.data[0][k])
                    })
                }
            })
        }
    }, [location])

    return <div className="col view" style={{ alignItems: 'center', paddingTop: '6%', overflow: 'hidden auto' }}>
        <div className="col" style={{ gap: '3.2rem' }}>
            <Text className="heading-3">{_pid ? "Edit project" : "New project"}</Text>
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Name'}
                label={'Name'}
            />
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Sologan'}
                label={'Sologan'}
            />
            <ImportFileForm
                required
                control={methods.control}
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                name="LogoId"
                label="Logo"
                title="Upload logo"
                direction="column"
                allowType={["image/svg+xml"]}
            />
            <CKEditorForm
                className={'row'}
                required
                labelStyle={{ width: "16rem" }}
                style={{ width: '68rem', gap: '0.4rem' }}
                errors={methods.formState.errors}
                control={methods.control}
                name={'Description'}
                label={'Description'}
                placeholder={'Write something abpout this project: "This is a project,...'}
            />
            <div className={'row'} style={{ justifyContent: 'end' }}>
                <button type="button" className={`row ${methods.watch('Name') && methods.watch('Description') ? 'button-primary' : 'button-disabled'}`} onClick={methods.handleSubmit(onSubmit)}>
                    <Text className="button-text-3">Tiếp tục</Text>
                </button>
            </div>
        </div>
        <NavLink to={'/home'} className={'icon-button32 row'} style={{ position: 'fixed', right: '6%' }}>
            <Winicon src={"fill/user interface/c-remove"} />
        </NavLink>
    </div>
}