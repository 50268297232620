import { ToastMessage } from 'wini-web-components'
import styles from '../assest.module.css'
import { SettingDataController } from '../../controller'
import { useEffect, useState } from 'react'
import { ComponentType } from '../../../home/table/da'
import RenderFormByType from '../../manager/formByType'
import CaptureElement from '../../../../project-component/captureElement'

export default function TbAssetsForm({ tbName = "" }) {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _settingDataController = new SettingDataController({ pid: _pid, setting: "form" })
    const [forms, setForms] = useState({ data: [] })
    const _iframe = document.getElementById("iframe-render-page-view")

    const getForms = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 10, query: `@TbName:{${tbName}}` })
        if (res.code === 200) {
            setForms({
                data: [...forms.data, ...res.data.map(e => { return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props } })],
                totalCount: res.totalCount
            })
        } else ToastMessage.errors(res.message)
    }

    useEffect(() => {
        document.body.querySelector(".setting-page-sidebar.left").style.width = "448px"
        getForms()
    }, [])

    return <div className='row' style={{ flexWrap: "wrap", padding: '0.8rem 1.2rem', gap: '1.2rem', width: "100%" }}>
        {forms.data.map((e) => {
            return <div key={e.Id} draggable className={`col col12 ${styles['card-option-demo']} ${styles['component-options']}`}
                onDragStart={(ev) => {
                    ev.dataTransfer.setData("component-id", e.Id)
                    ev.dataTransfer.setData("component-type", ComponentType.form)
                    _iframe.classList.add('dragging')
                }}
                onDragEnd={() => {
                    _iframe.classList.remove('dragging')
                    _iframe.contentWindow.document.body.querySelector(`div[class*="demo-component-in-container"]`)?.remove()
                }}
            >
                <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1 }}>
                    <RenderFormByType
                        pid={_pid}
                        style={{ height: '40rem', width: '100%', backgroundColor: "var(--neutral-absolute-background-color)", gap: '2.4rem', borderRadius: '0.8rem', border: "var(--neutral-bolder-border-color)" }}
                        formItem={e}
                    />
                </CaptureElement>
            </div>
        })}
    </div>
}