import styles from '../view.module.css'
import indexStyles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { SettingDataController } from "../../../module/controller"
import { TableController } from "../controller"
import { useFieldArray, useForm } from "react-hook-form"
import { randomGID } from "../../../../Utils"
import { ComponentType } from "../da"
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage, Winicon } from "wini-web-components"
import { OutlineButton, OutlineContainer } from "../../../../assets/icon"
import LayerTile from "./LayerTile"
import RenderCard from './renderCard'
import { TextAreaForm, TextFieldForm } from '../../../../project-component/component-form'
import CardInforContainer from './cardInfor'

export const DrawerSettingCard = forwardRef(function DrawerSettingCard(data, ref) {
    const _settingDataController = new SettingDataController({ pid: data.pid, setting: "card" })
    const _relController = new TableController(data.pid, "rel")
    const methods = useForm({ shouldFocusError: false, defaultValues: {} })
    const methodProps = useForm({
        shouldFocusError: false, defaultValues: {
            selectedId: undefined,
            hoverId: undefined,
            layers: [{ Id: randomGID(), Type: ComponentType.container, DateCreated: Date.now(), ProjectId: data.pid ?? [], Name: "New card", Setting: { style: { width: "100%", height: "100%", backgroundColor: "#fff" }, className: "col" } }]
        }
    })
    const _layers = useFieldArray({
        name: "layers",
        control: methodProps.control,
        keyName: undefined
    })
    const dialogRef = useRef()
    const demoCardRef = useRef()
    const [pkRels, setPkRels] = useState([])
    const [fkRels, setFkRels] = useState([])
    const componentType = [ComponentType.container, ComponentType.text, ComponentType.button, ComponentType.img, ComponentType.icon, ComponentType.navLink]

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Confirm save',
            content: "Every changes will be applied on this card",
            submitTitle: "Submit",
            cancelTitle: "Cancel",
            onSubmit: async () => {
                let _cardData = { ...ev, Props: methodProps.getValues() }
                _cardData.Props = JSON.stringify(_cardData.Props)
                const res = await _settingDataController.action("edit", { data: [_cardData] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                data.onSubmit()
                closePopup(ref)
            }
        })
    }

    useEffect(() => {
        if (data.cardItem) {
            Object.keys(data.cardItem).forEach((p) => {
                if (data.cardItem[p] !== undefined) {
                    methods.setValue(p, data.cardItem[p])
                    if (p === "Props") {
                        const _props = typeof data.cardItem[p] === "string" ? JSON.parse(data.cardItem[p]) : data.cardItem[p]
                        Object.keys(_props).forEach(pk => {
                            methodProps.setValue(pk, _props[pk])
                        })
                    }
                }
            })
        }
    }, [])

    const handleDragToTarget = (ev) => {
        ev.preventDefault()
        const _parent = ev.target.closest('div[component-type="Container"]')
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent?.id?.length === 32) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const renderComponentOption = (prop) => {
        switch (prop) {
            case ComponentType.container:
                icon = <OutlineContainer size={'2.8rem'} />
                break;
            case ComponentType.text:
                var icon = <Winicon src='fill/text/text' />
                break;
            case ComponentType.button:
                icon = <OutlineButton size={'2.8rem'} />
                break;
            case ComponentType.img:
                icon = <Winicon src={"fill/development/image"} />
                break;
            case ComponentType.icon:
                icon = <Winicon src={"fill/development/icon"} />
                break;
            case ComponentType.navLink:
                icon = <Winicon src={"fill/user interface/link"} />
                break;
            default:
                return null
        }
        return <div key={prop} draggable className={`col col8 ${styles['component-options']}`} style={{ alignItems: 'center' }}
            onDragStart={(ev) => { ev.dataTransfer.setData("component-type", prop) }}
            onDragEnd={(ev) => { demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)?.remove() }}
        >
            {icon}
            <Text className="regular1" maxLine={1}>{prop}</Text>
        </div>
    }

    const onDrop = (ev) => {
        ev.preventDefault()
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_demo) {
            const _parent = _demo.parentElement
            const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
            const _compType = ev.dataTransfer.getData('component-type')
            let newLayer = {
                Id: randomGID(),
                DateCreated: Date.now(),
                ParentId: _parent.id,
                Name: _compType,
                Type: _compType,
                Setting: { style: { order: _children.findIndex(el => !el.id) } }
            }
            switch (_compType) {
                case ComponentType.container:
                    if (window.getComputedStyle(_parent).flexDirection === "row") {
                        newLayer.Setting.style.width = '4.8rem'
                    } else {
                        newLayer.Setting.style.height = '4.8rem'
                    }
                    break;
                case ComponentType.img:
                    newLayer.Setting.src = "https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg"
                    newLayer.Setting.style = { borderRadius: '0.8rem', width: '2.4rem', height: '2.4rem', ...newLayer.Setting.style }
                    break;
                case ComponentType.text:
                    newLayer.Setting.value = "Text"
                    newLayer.Setting.className = "heading-6"
                    break;
                case ComponentType.button:
                    newLayer.Setting.label = "Button"
                    break;
                default:
                    break;
            }
            methodProps.setValue("layers", [...methodProps.getValues("layers").map((e) => {
                const _index = _children.findIndex(el => el.id === e.Id)
                if (_index >= 0) return { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style, order: _index } } }
                return e
            }), newLayer])
            _parent.removeChild(_demo)
        }
    }

    useEffect(() => {
        const _selectedId = methodProps.getValues("selectedId")
        if (_selectedId) {
            const _rect = demoCardRef.current.getBoundingClientRect()
            const _layerRect = demoCardRef.current.querySelector(`*[id="${_selectedId}"]`).getBoundingClientRect()
            demoCardRef.current.style.setProperty('--selectedX', `${_layerRect.x - _rect.x}px`)
            demoCardRef.current.style.setProperty('--selectedY', `${_layerRect.y - _rect.y}px`)
            demoCardRef.current.style.setProperty('--selectedW', `${_layerRect.width}px`)
            demoCardRef.current.style.setProperty('--selectedH', `${_layerRect.height}px`)
            demoCardRef.current.setAttribute("selected-name", _layers.fields.find(e => e.Id === _selectedId)?.Name ?? "")
        }
    }, [methodProps.watch("selectedId")])

    // useEffect(() => {
    //     const _hoverId = methodProps.getValues("hoverId")
    //     if (_hoverId) {
    //         const _rect = demoCardRef.current.getBoundingClientRect()
    //         const _layerRect = demoCardRef.current.querySelector(`div[id="${_hoverId}"]`).getBoundingClientRect()
    //         demoCardRef.current.style.setProperty('--hoverX', `${_layerRect.x - _rect.x}px`)
    //         demoCardRef.current.style.setProperty('--hoverY', `${_layerRect.y - _rect.y}px`)
    //         demoCardRef.current.style.setProperty('--hoverW', `${_layerRect.width}px`)
    //         demoCardRef.current.style.setProperty('--hoverH', `${_layerRect.height}px`)
    //         demoCardRef.current.setAttribute("hover-name", _layers.fields.find(e => e.Id === _hoverId)?.Name ?? "")
    //     }
    // }, [methodProps.watch("hoverId")])

    return <div className={`col ${indexStyles['setting-card-item-container']}`}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.cardItem?.Name ?? "New card"}</Text>
            <button type='button' className='row icon-button28' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} size={'2rem'} />
            </button>
        </div>
        <div className='row' style={{ padding: "1.6rem 2.4rem", alignItems: 'start', height: "100%", flex: 1 }}>
            <div className={`col col6`} style={{ '--gutter': "0px", borderRight: "var(--neutral-bolder-border-color)", height: "100%" }}>
                <Text className='heading-8'>Element</Text>
                <div className={`row ${styles['component-options-group']}`}>
                    {componentType.map((prop) => renderComponentOption(prop))}
                </div>
                <div className={`col ${indexStyles['layer-list-container']}`}>
                    {_layers.fields.filter(e => !e.ParentId).map(e => {
                        return <LayerTile
                            key={e.Id}
                            item={e}
                            layers={_layers.fields}
                            methods={methodProps}
                        />
                    })}
                </div>
            </div>
            <div className={`col col12 ${indexStyles['overview-container']}`}>
                <Text className='heading-8'>Overview</Text>
                <div ref={demoCardRef} className='col' onDragOver={handleDragToTarget} onDrop={onDrop}>
                    <RenderCard layers={_layers.fields} />
                </div>
            </div>
            <CardInforContainer methods={methods} methodsProps={methodProps} />
        </div>
        <div className={`row`} style={{ padding: "1.6rem 2.4rem", borderTop: "var(--neutral-bolder-border-color)", justifyContent: "end", gap: "0.8rem" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "#fff", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm cancel',
                        content: "Every changes will be unsaved",
                        submitTitle: "Submit",
                        cancelTitle: "Cancel",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})